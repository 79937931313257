import React from "react";
import {
  Route, Switch
} from "react-router-dom";
import Home from './Home';
import ImageDetail from './ImageDetail';
import Images from './Images';
import Labels from './Labels';
import Logout from './Logout';
import Projects from './Projects';
import Settings from './Settings';
import Signin from './Signin';
import Signup from './Signup';
import Teams from './Teams';
import Demo from './Demo';
import VideoDetail from './VideoDetail';
import Videos from './Videos';
import Spaces from './Spaces';

export default function AppRouter() {
  return (
    <>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/labels">
            <Labels />
          </Route>
          <Route exact path="/images">
            <Images />
          </Route>
          <Route path="/images/:id">
            <ImageDetail />
          </Route>
          <Route exact path="/videos">
            <Videos />
          </Route>
          <Route path="/videos/:id">
            <VideoDetail />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/neuralnets">
            <Projects />
          </Route>
          <Route path="/teams">
            <Teams />
          </Route>
          <Route path="/play">
            <Demo />
          </Route>
          <Route path="/spaces">
            <Spaces />
          </Route>
          <Route path="/signin">
            <Signin />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/logout">
            <Logout />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
        </Switch>
    </>
  );
}