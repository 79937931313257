/**
 * @fileoverview gRPC-Web generated client stub for chupu
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.chupu = require('./projects_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.chupu.ProjectsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.chupu.ProjectsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chupu.CreateProjectRequest,
 *   !proto.chupu.Project>}
 */
const methodDescriptor_Projects_CreateProject = new grpc.web.MethodDescriptor(
  '/chupu.Projects/CreateProject',
  grpc.web.MethodType.UNARY,
  proto.chupu.CreateProjectRequest,
  proto.chupu.Project,
  /**
   * @param {!proto.chupu.CreateProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Project.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.chupu.CreateProjectRequest,
 *   !proto.chupu.Project>}
 */
const methodInfo_Projects_CreateProject = new grpc.web.AbstractClientBase.MethodInfo(
  proto.chupu.Project,
  /**
   * @param {!proto.chupu.CreateProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Project.deserializeBinary
);


/**
 * @param {!proto.chupu.CreateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.chupu.Project)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chupu.Project>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chupu.ProjectsClient.prototype.createProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chupu.Projects/CreateProject',
      request,
      metadata || {},
      methodDescriptor_Projects_CreateProject,
      callback);
};


/**
 * @param {!proto.chupu.CreateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chupu.Project>}
 *     A native promise that resolves to the response
 */
proto.chupu.ProjectsPromiseClient.prototype.createProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chupu.Projects/CreateProject',
      request,
      metadata || {},
      methodDescriptor_Projects_CreateProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chupu.GetProjectRequest,
 *   !proto.chupu.Project>}
 */
const methodDescriptor_Projects_GetProject = new grpc.web.MethodDescriptor(
  '/chupu.Projects/GetProject',
  grpc.web.MethodType.UNARY,
  proto.chupu.GetProjectRequest,
  proto.chupu.Project,
  /**
   * @param {!proto.chupu.GetProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Project.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.chupu.GetProjectRequest,
 *   !proto.chupu.Project>}
 */
const methodInfo_Projects_GetProject = new grpc.web.AbstractClientBase.MethodInfo(
  proto.chupu.Project,
  /**
   * @param {!proto.chupu.GetProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Project.deserializeBinary
);


/**
 * @param {!proto.chupu.GetProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.chupu.Project)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chupu.Project>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chupu.ProjectsClient.prototype.getProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chupu.Projects/GetProject',
      request,
      metadata || {},
      methodDescriptor_Projects_GetProject,
      callback);
};


/**
 * @param {!proto.chupu.GetProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chupu.Project>}
 *     A native promise that resolves to the response
 */
proto.chupu.ProjectsPromiseClient.prototype.getProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chupu.Projects/GetProject',
      request,
      metadata || {},
      methodDescriptor_Projects_GetProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chupu.ListProjectsRequest,
 *   !proto.chupu.ListProjectsReponse>}
 */
const methodDescriptor_Projects_ListProjects = new grpc.web.MethodDescriptor(
  '/chupu.Projects/ListProjects',
  grpc.web.MethodType.UNARY,
  proto.chupu.ListProjectsRequest,
  proto.chupu.ListProjectsReponse,
  /**
   * @param {!proto.chupu.ListProjectsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.ListProjectsReponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.chupu.ListProjectsRequest,
 *   !proto.chupu.ListProjectsReponse>}
 */
const methodInfo_Projects_ListProjects = new grpc.web.AbstractClientBase.MethodInfo(
  proto.chupu.ListProjectsReponse,
  /**
   * @param {!proto.chupu.ListProjectsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.ListProjectsReponse.deserializeBinary
);


/**
 * @param {!proto.chupu.ListProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.chupu.ListProjectsReponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chupu.ListProjectsReponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chupu.ProjectsClient.prototype.listProjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chupu.Projects/ListProjects',
      request,
      metadata || {},
      methodDescriptor_Projects_ListProjects,
      callback);
};


/**
 * @param {!proto.chupu.ListProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chupu.ListProjectsReponse>}
 *     A native promise that resolves to the response
 */
proto.chupu.ProjectsPromiseClient.prototype.listProjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chupu.Projects/ListProjects',
      request,
      metadata || {},
      methodDescriptor_Projects_ListProjects);
};


module.exports = proto.chupu;

