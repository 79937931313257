import * as React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import VideocamIcon from '@mui/icons-material/Videocam';
import Stack from '@mui/material/Stack';

const Input = styled('input')({
  display: 'none',
});

export default function UploadButtons({onFileChange}) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {/* <label htmlFor="contained-button-file">
        <Input accept="image/*" id="contained-button-file" multiple type="file" />
        <Button variant="contained" component="span">
          Upload
        </Button>
      </label> */}
      <label htmlFor="icon-button-file1">
        <Input accept="image/*" id="icon-button-file1" type="file" onChange={onFileChange} />
        <IconButton color="primary" aria-label="upload picture" component="span">
          <PhotoCamera />
        </IconButton>
      </label>
      <label htmlFor="icon-button-file2">
        <Input accept="video/mp4,video/x-m4v,video/*" id="icon-button-file2" type="file" onChange={onFileChange} />
        <IconButton color="primary" aria-label="upload video" component="span">
          <VideocamIcon />
        </IconButton>
      </label>
    </Stack>
  );
}
