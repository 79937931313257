import React from "react";

import { makeStyles, styled } from '@mui/styles';
import { Divider, Paper } from '@mui/material';


import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';
import BrushIcon from '@mui/icons-material/Brush';
import CropFreeIcon from '@mui/icons-material/CropFree';
import TimelineIcon from '@mui/icons-material/Timeline';
import LayersIcon from '@mui/icons-material/Layers';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';


const ToolIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '20px',
  padding: '4px'
}));


const ImageTools = (props) => {

  return (
  <>
   <Paper>
      <MenuList>
        <MenuItem>
          <ToolIcon>
            <AddIcon fontSize="small" />
          </ToolIcon>
        </MenuItem>
        <MenuItem>
          <ToolIcon>
            <RemoveIcon fontSize="small" />
          </ToolIcon>
        </MenuItem>
        <MenuItem>
          <ToolIcon>
            <BrightnessMediumIcon fontSize="small" />
          </ToolIcon>
        </MenuItem>
        <MenuItem>
          <ToolIcon>
            <BrushIcon fontSize="small" />
          </ToolIcon>
        </MenuItem>
        <MenuItem>
          <ToolIcon>
            <CropFreeIcon fontSize="small" />
          </ToolIcon>
        </MenuItem>
        <MenuItem>
          <ToolIcon>
            <TimelineIcon fontSize="small" />
          </ToolIcon>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ToolIcon>
            <AutoFixHighIcon fontSize="small" />
          </ToolIcon>
        </MenuItem>
        <MenuItem>
          <ToolIcon>
            <SettingsAccessibilityIcon fontSize="small" />
          </ToolIcon>
        </MenuItem>
        <MenuItem>
          <ToolIcon>
            <LayersIcon fontSize="small" />
          </ToolIcon>
        </MenuItem>
      </MenuList>
    </Paper>
    </>
  );
};

export default ImageTools

