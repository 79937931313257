import React, { useEffect, useState, useRef } from 'react';
import { TextField, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import Table from './Table';
import { MainCont, Header, CreateCont } from './CommonCrud';
import { ProjectsClient } from './proto/projects_grpc_web_pb'
import { CreateProjectRequest, ListProjectsRequest } from './proto/projects_pb'
import useStore from "./loaderStore";

function Projects() {
  const ariaLabel = { 'aria-label': 'description' };
  const [projects, setProjects] = useState([]);
  const [rows, setTableData] = useState([]);
  const [newProjectName, setNewProjectName] = useState("");
  const setLoading = useStore(state => state.setLoading);

  const projectRef = useRef(null);

  const getProjects = () => {
    setLoading(true)
    const req = new ListProjectsRequest();
    const client = new ProjectsClient(process.env.REACT_APP_ENVOY_PROXY);
    client.listProjects(req, {}, (err, resp) => {
      if (err) {
        console.log(err.code);
        console.log(err.message);
      } else {
        setProjects(resp.getProjectList())
        setLoading(false)
      }
    })
  }
  
  const createProject = (name, projectRef) => {
    setLoading(true)
    const req = new CreateProjectRequest();
    const client = new ProjectsClient(process.env.REACT_APP_ENVOY_PROXY);
    req.setName(name)
    client.createProject(req, {}, (err, resp) => {
      if (err) {
        console.log(err.code);
        console.log(err.message);
      } else {
        getProjects()
        setNewProjectName("")
        setLoading(false)
      }
    })
  }
  
  useEffect(() => {
    getProjects()
  },[])
  
  useEffect(() => {
    const result = []
    projects.map(prj => {
      result.push({name: prj.getName(), created: prj.getCreateTime().toDate().toString(), updated: prj.getUpdateTime().toDate().toString()})
    })
    setTableData(result)
  },[projects])

  return (
    <MainCont>
      <Header >
        Projects
        <CreateCont>
          <TextField variant="standard" size="small" ref={projectRef} placeholder="Create a new project" value={newProjectName} inputProps={ariaLabel} onChange={e => setNewProjectName(e.target.value)} 
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                // Do code here
                createProject(newProjectName, projectRef)
                ev.preventDefault();
              }
            }}/>
          <IconButton color="secondary" aria-label="create project" onClick={() => createProject(newProjectName, projectRef)}>
            <SaveIcon />
          </IconButton>
        </CreateCont>
      </Header>
      <Table rows={rows}/>
    </MainCont>
  );
}
export default Projects