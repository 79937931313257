import React from 'react';
import {  Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

function Signin() {
  const classes = useStyles();
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
          id="filled-password-input"
          label="Username"
          variant="filled"
      /><br/>
      <TextField
          id="filled-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          variant="filled"
      /><br/>
      <Button variant="outlined" color="primary">
        Sign In
      </Button>
      {/* <LoginButton /> */}
    </form>
  );
}
export default Signin