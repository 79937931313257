import React , { useState } from 'react';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey, lightGreen, amber } from '@mui/material/colors';
import ColorModeContext from './appStore';
import MiniDrawer from './MiniDrawer';
import Loader from './Loader';
import useStore from "./loaderStore";

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      ...amber,
      ...(mode === 'dark' && {
        main: grey[700],
        light: grey[600],
      }),
      ...(mode === 'light' && {
        main: grey[400],
      }),
    },
    ...(mode === 'dark' && {
      background: {
        default: grey[900],
        paper: grey[900],
      },
    }),
    ...(mode === 'light' && {
      background: {
        default: grey[50],
        paper: grey[100],
      },
    }),
    secondary: {
      ...lightGreen,
      ...(mode === 'dark' && {
        main: lightGreen['A700'],
        dark: amber['A700']
      }),
      ...(mode === 'light' && {
        main: lightGreen['A700'],
      }),
    },
    lighter: {
      ...grey,
      ...(mode === 'dark' && {
        main: lightGreen['A700'],
        light: grey['A400']
      }),
      ...(mode === 'light' && {
        main: lightGreen['A700'],
      }),
    },
    text: {
      ...(mode === 'light'
        ? {
            primary: grey[900],
            secondary: grey[800],
          }
        : {
            primary: '#fff',
            secondary: grey[500],
          }),
    },
  }
});


// const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function App() {
  const loading = useStore(state => state.loading);
  const setLoading = useStore(state => state.setLoading);
  
  const curDrawerState = localStorage.getItem('drawerOpen');
  const [show, setDrawerState] = useState(JSON.parse(curDrawerState));
  
  const toggleDrawer = (event, val) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    localStorage.setItem('drawerOpen', val)
    setDrawerState(val);
  };

  const handleClose = (value) => {
    setLoading(false)
  };

  const existingMode = localStorage.getItem('colormode');
  
  const [mode, setMode] = useState(existingMode ? existingMode : 'dark');
  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) =>{
          let mode = ''
          if(prevMode === 'light') {
            mode = 'dark'
          } else {
            mode ='light'
          }
          localStorage.setItem('colormode', mode)
          return mode
        }
        );
      },
    }),
    [],
  );

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <Router>
            <MiniDrawer show={show} toggleDrawer={toggleDrawer}/>
          </Router>
          <Loader
            open={loading}
            onClose={handleClose}
          />
        </ThemeProvider>
    </ColorModeContext.Provider>
      
  );
}

export default App;
