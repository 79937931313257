/**
 * @fileoverview gRPC-Web generated client stub for chupu
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.chupu = require('./spaces_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.chupu.SpacesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.chupu.SpacesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chupu.CreateSpaceRequest,
 *   !proto.chupu.Space>}
 */
const methodDescriptor_Spaces_CreateSpace = new grpc.web.MethodDescriptor(
  '/chupu.Spaces/CreateSpace',
  grpc.web.MethodType.UNARY,
  proto.chupu.CreateSpaceRequest,
  proto.chupu.Space,
  /**
   * @param {!proto.chupu.CreateSpaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Space.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.chupu.CreateSpaceRequest,
 *   !proto.chupu.Space>}
 */
const methodInfo_Spaces_CreateSpace = new grpc.web.AbstractClientBase.MethodInfo(
  proto.chupu.Space,
  /**
   * @param {!proto.chupu.CreateSpaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Space.deserializeBinary
);


/**
 * @param {!proto.chupu.CreateSpaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.chupu.Space)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chupu.Space>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chupu.SpacesClient.prototype.createSpace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chupu.Spaces/CreateSpace',
      request,
      metadata || {},
      methodDescriptor_Spaces_CreateSpace,
      callback);
};


/**
 * @param {!proto.chupu.CreateSpaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chupu.Space>}
 *     A native promise that resolves to the response
 */
proto.chupu.SpacesPromiseClient.prototype.createSpace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chupu.Spaces/CreateSpace',
      request,
      metadata || {},
      methodDescriptor_Spaces_CreateSpace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chupu.GetSpaceRequest,
 *   !proto.chupu.Space>}
 */
const methodDescriptor_Spaces_GetSpace = new grpc.web.MethodDescriptor(
  '/chupu.Spaces/GetSpace',
  grpc.web.MethodType.UNARY,
  proto.chupu.GetSpaceRequest,
  proto.chupu.Space,
  /**
   * @param {!proto.chupu.GetSpaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Space.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.chupu.GetSpaceRequest,
 *   !proto.chupu.Space>}
 */
const methodInfo_Spaces_GetSpace = new grpc.web.AbstractClientBase.MethodInfo(
  proto.chupu.Space,
  /**
   * @param {!proto.chupu.GetSpaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Space.deserializeBinary
);


/**
 * @param {!proto.chupu.GetSpaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.chupu.Space)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chupu.Space>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chupu.SpacesClient.prototype.getSpace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chupu.Spaces/GetSpace',
      request,
      metadata || {},
      methodDescriptor_Spaces_GetSpace,
      callback);
};


/**
 * @param {!proto.chupu.GetSpaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chupu.Space>}
 *     A native promise that resolves to the response
 */
proto.chupu.SpacesPromiseClient.prototype.getSpace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chupu.Spaces/GetSpace',
      request,
      metadata || {},
      methodDescriptor_Spaces_GetSpace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chupu.ListSpacesRequest,
 *   !proto.chupu.ListSpacesReponse>}
 */
const methodDescriptor_Spaces_ListSpaces = new grpc.web.MethodDescriptor(
  '/chupu.Spaces/ListSpaces',
  grpc.web.MethodType.UNARY,
  proto.chupu.ListSpacesRequest,
  proto.chupu.ListSpacesReponse,
  /**
   * @param {!proto.chupu.ListSpacesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.ListSpacesReponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.chupu.ListSpacesRequest,
 *   !proto.chupu.ListSpacesReponse>}
 */
const methodInfo_Spaces_ListSpaces = new grpc.web.AbstractClientBase.MethodInfo(
  proto.chupu.ListSpacesReponse,
  /**
   * @param {!proto.chupu.ListSpacesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.ListSpacesReponse.deserializeBinary
);


/**
 * @param {!proto.chupu.ListSpacesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.chupu.ListSpacesReponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chupu.ListSpacesReponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chupu.SpacesClient.prototype.listSpaces =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chupu.Spaces/ListSpaces',
      request,
      metadata || {},
      methodDescriptor_Spaces_ListSpaces,
      callback);
};


/**
 * @param {!proto.chupu.ListSpacesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chupu.ListSpacesReponse>}
 *     A native promise that resolves to the response
 */
proto.chupu.SpacesPromiseClient.prototype.listSpaces =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chupu.Spaces/ListSpaces',
      request,
      metadata || {},
      methodDescriptor_Spaces_ListSpaces);
};


module.exports = proto.chupu;

