import React, {useRef} from 'react';
import ReactPlayer from 'react-player'
import VideoControls from './VideoControls';
import videoStore from "./videoStore";
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Player = styled(ReactPlayer)(({ theme, src }) => ({
  '& .video': {
    // maxHeight: '50vh'
  }
}));

function VideoPlayer({url}) {
  const playing = videoStore(state => state.playing);
  const volume = videoStore(state => state.volume);
  const setReady = videoStore(state => state.setReady);
  const onProgress = videoStore(state => state.onProgress);
  const playerRef = useRef();
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <> 
      <Player progressInterval={50} playsinline url={url} width='65vw' height={matches ? '55vh': 'auto'} playing={playing} ref={playerRef} onReady={setReady} onProgress={onProgress} volume={volume}/>
      <VideoControls playerRef={playerRef}/>
    </>
  );
}

export default VideoPlayer
