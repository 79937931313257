import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import videoStore from "./videoStore";
import { Box, ListItem, Divider } from '@mui/material';
import loaderStore from "./loaderStore";
import Konva from 'konva';

const ClipBox = styled(Box)(({ theme, src }) => ({
  position: 'relative',
  maxHeight: '60vh',
  left: '-4vw',
  width: '69vw'
}));

const ClipList = styled('div')(({ theme, src }) => ({
  maxHeight: '20vh',
  overflow: 'scroll',
}));

const LabelTitle = styled('div')(({ theme, src }) => ({
  width: '4vw',
  overflow: 'scroll',
  margin: 2,
  fontSize: 10
}));


const ListItemAlt = styled(ListItem)(({ theme, itemId }) => ({
  paddingLeft: 0,
  paddingRight: 0,
}));

const ClipMask = styled('div')(({ theme, color, clipMask }) => ({
  height: '8px',
  width: '100%',
  background: color,
  clipPath: `url(#maskClip${clipMask})`,
  zIndex: 1
}));

const style = {
  width: '100%',
  bgcolor: 'background.paper',
};



function ClipRow({ labelName, positions, duration, empty }) {
  const [color, setColor] = useState(null)
  const markerRef = useRef();
  const getScaled = (pos, markerRef) => {
    return Math.floor(pos * markerRef.current.offsetWidth / duration)
  }

  useEffect(() => {
    setColor(Konva.Util.getRandomColor());
  }, [])

  return (
    <>
      <Divider />
      <ListItemAlt itemID={labelName} button>
        <LabelTitle>{labelName}</LabelTitle>
        {!empty && <ClipMask color={color} clipMask={labelName} ref={markerRef}/>}
        { markerRef && markerRef.current &&
        <svg width='0' height='0'>
          <defs>
            <clipPath id={`maskClip${labelName}`}>
              {positions && positions.map(pos => {
                const scaledX = getScaled(pos[0], markerRef);
                const scaledWidth = getScaled(pos[1], markerRef) - scaledX;
                return <rect x={scaledX} width={scaledWidth} height="10" />
              })}
            </clipPath>
          </defs>
        </svg>
        }
      </ListItemAlt>
    </>)
}

function ClipView({ playerRef }) {
  const setLoading = loaderStore(state => state.setLoading);
  const ready = videoStore(state => state.ready);
  const tagClips = videoStore(state => state.tagClips);
  const [duration, setDuration] = React.useState(0);


  useEffect(() => {
    if(ready) {
      setDuration(playerRef.current.getDuration());
      setLoading(false);
    }
  }, [ready])

  return (
    <ClipBox
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <ClipList sx={style} aria-label="labels">
        {Object.entries(tagClips).length === 0 && <ClipRow labelName={'No tags'} key={1} duration={duration} empty/>}
        {Object.entries(tagClips).map(([key, val]) => {
          return <ClipRow labelName={key} key={key} positions={val} duration={duration}/>
        })}
      </ClipList>
    </ClipBox>
  );
}

export default ClipView
