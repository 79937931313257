import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Divider, 
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip } from '@mui/material';
import { ListLabelsRequest } from './proto/labels_pb'
import loaderStore from "./loaderStore";
import videoStore from "./videoStore";
import SaveIcon from '@mui/icons-material/Save';
import AdjustIcon from '@mui/icons-material/Adjust';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { LabelsClient } from './proto/labels_grpc_web_pb'

const ToolIcon = styled('div')(({ theme }) => ({
  minWidth: '40px',
  padding: '4px',
  color: theme.palette.primary.dark,
  cursor: 'pointer'
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left'
}));

const RunningTag = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '2px',
}));

const Stopped = styled('label')(({ theme }) => ({
  color: 'red',
  fontSize: 10
}));

const RunningTagLabel = styled('label')(({ theme, stopped}) => ({
  marginRight: '4px',
  textDecoration: stopped === 'true' ? 'line-through' : '',
  fontStyle: stopped === 'true' ? 'italic' : '',
  fontSize: 12
}));

const LiveIcon = styled(AdjustIcon)(({ theme }) => ({
  cursor: 'pointer',
  width: 16,
  paddingRight: 2
}));
const StopIcon = styled(StopCircleIcon)(({ theme }) => ({
  cursor: 'pointer',
  width: 16,
  paddingRight: 2
}));

const TagDivider = styled(Divider)(({ theme }) => ({
  margin: '20px 0px'
}));


export default function VideoLabelDialog() {
  const dialogOpen = videoStore(state => state.dialogOpen);
  const setDialogOpen = videoStore(state => state.setDialogOpen);
  const runTags = videoStore(state => state.runTags);
  const setRunTags = videoStore(state => state.setRunTags);
  const stopTags = videoStore(state => state.stopTags);
  const setStopTags = videoStore(state => state.setStopTags);
  const position = videoStore(state => state.position);
  const [selectedTag, setSelectedTag] = React.useState('dog');
  const tagClips = videoStore(state => state.tagClips);
  const updateTagClips = videoStore(state => state.updateTagClips);
  const setLoading = loaderStore(state => state.setLoading);
  const [labels, setLabels] = useState([]);
  const [unRun, setUnRun] = useState([])

  const getLabels = () => {
    setLoading(true)
    const req = new ListLabelsRequest();
    const client = new LabelsClient(process.env.REACT_APP_ENVOY_PROXY);
    client.listLabels(req, {}, (err, resp) => {
      if (err) {
        console.log(err.code);
        console.log(err.message);
      } else {
        setLabels(resp.getLabelList())
        setLoading(false)
      }
    })
  }

  const updateRunTags = () => {
    // Add the new added run tags
    const clips = {...tagClips}
    runTags.map(rt => {
      if(!tagClips.hasOwnProperty(rt)){
        clips[rt] =  [[position, position]];        
      } else {
        clips[rt] =  [...clips[rt], [position, position]];
      }
    })

    // Stop some running tags
    let curRunTags = [...runTags]
    unRun.map(ur => {
      if(stopTags.indexOf(ur.tag) > -1){
        clips[ur.tag][ur.ix] = [clips[ur.tag][ur.ix][0], position]
      } else {
        curRunTags.splice(curRunTags.indexOf(ur.tag) , 1)
      }
    })

    stopTags.map(st => {
      curRunTags.splice(curRunTags.indexOf(st) , 1)
    })

    updateTagClips(clips)
    setRunTags(curRunTags)
    setStopTags([])
    setDialogOpen(false);
  };

  const addRunTag = (tag) => {
    if(runTags.indexOf(tag) === -1){
      setRunTags([...runTags, ...tag])
    }
  };

  const stopRunTag = (v) => {
    const temp = [...stopTags]
    const idx = temp.indexOf(v);
    if( idx === -1){
      setStopTags([...temp, v])
    }else{
      temp.splice(idx, 1)
      setStopTags(temp)
    }
  };

  useEffect(() => {
    let tempTags = {...tagClips};
    if(runTags && runTags.length > 0){
      runTags.map(rt => {
        let positions = tagClips[rt]
        if(positions && positions.length > 0){
          positions[positions.length - 1 ][1] = position;
          tempTags[rt] = [...positions]
        }
      })
      updateTagClips(tempTags)
    }
  }, [position])

  useEffect(() => {
    getLabels()
  }, [])

  useEffect(() => {
    if(dialogOpen){
      const urs = []
      const tags = []
      for (const [tag, positions] of Object.entries(tagClips)) {
        let ix = 0;
        if(tag.length > 0 ){
          for(ix = 0; ix < positions.length; ix++ ){
            if(position > positions[ix][0] && position < positions[ix][1]){
              tags.push(tag)
              urs.push({tag, ix})
              console.log(urs, positions, ix, position, tag)
            }
          }
        }
      }
      addRunTag(tags)
      setUnRun(urs)
    }
  }, [dialogOpen])

  const checkStopped = (v) => {
    if(stopTags.indexOf(v) === -1){
      return false
    }
    return true
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth={'sm'}
        open={dialogOpen}
        onClose={updateRunTags}
      >
        <DialogTitle>Tag manager</DialogTitle>
        <StyledDialogContent>
          {runTags.length !== 0 &&
            <>
              <DialogContentText>
                Stop a running tag
              </DialogContentText>
              <div>
                {runTags.length !== 0 && runTags.map((v) => {
                  return (
                  <RunningTag key={v}>
                    {checkStopped(v) && <StopIcon color="error" onClick={() => stopRunTag(v)}/>}
                    {!checkStopped(v) && <LiveIcon color="secondary" onClick={() => stopRunTag(v)}/>}
                    <RunningTagLabel stopped={checkStopped(v).toString()}>
                      {v}
                    </RunningTagLabel>
                    {checkStopped(v) && <Stopped>stopped</Stopped>}
                  </RunningTag>
                  )
                })}
              </div>
              <TagDivider />
            </>
            
          }
          <DialogContentText>
            Start a new tag
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            <FormControl sx={{ mt: 2, mb: 2, mr: 2, minWidth: 120 }}>
              <InputLabel htmlFor="max-width">label</InputLabel>
              {labels && 
                <Select
                  autoFocus
                  value={selectedTag}
                  onChange={e => setSelectedTag(e.target.value)}
                  label="label"
                  inputProps={{
                    name: 'max-width',
                    id: 'max-width',
                  }}
                >
                  {labels.map(label => {return <MenuItem value={label.getName()}>{label.getName()}</MenuItem>})}
                </Select>
              }
            </FormControl>
            <AddCircleOutlineIcon color="secondary" onClick={() => addRunTag([selectedTag])} />
          </Box>
        </StyledDialogContent>
        <DialogActions>
          <Tooltip title="save tags" placement="bottom">
            <ToolIcon onClick={updateRunTags}>
              <SaveIcon fontSize="large" />
            </ToolIcon>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
