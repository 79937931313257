import React from "react";
import { styled } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const NavBtnCpnt = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
}));


const ImageDetailsFooter =  () => {
  return (
    <NavBtnCpnt>
      <ChevronLeftIcon />
      <ChevronRightIcon />
    </NavBtnCpnt>
  );
};
export default ImageDetailsFooter