import React, { useEffect, useState, useRef } from 'react';
import Upload from './Upload';
import { styled } from '@mui/material/styles';
import ImageDetail from './ImageDetail';
import VideoPlayer from './VideoPlayer';
import Webcam from './Webcam';
import demoStore from './demoStore';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
export const MainCont = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

function Demo() {
  const [demoFile, setFile] = useState(null);
  const [fileFromIdb, setFileLocal] = useState(null);
  const [db, setDB] = useState(null);
  const [webcam, setWebcam] = useState(false);
  const setDemo = demoStore(state => state.setDemo);
  const [facingMode, setFacingMode] = useState('environment')

  
  const onFileChange = (e) => {
    setFileLocal(null)
    console.log('File has changed!!!', e)
    setFile(e.target.files[0]);
    e.target.value = ''
  }

  const initDb = () => {
    let request = window.indexedDB.open('chupu_demo_db', 1);
    request.onerror = function() {
      console.log('Database failed to open');
    };
    
    // onsuccess handler signifies that the database opened successfully
    request.onsuccess = function() {
      console.log('Database opened successfully');
    
      // Store the opened database object in the db variable. This is used a lot below
      setDB(request.result)
    };

    // Setup the database tables if this has not already been done
    request.onupgradeneeded = function(e) {
      // Grab a reference to the opened database
      let idb = e.target.result;
      // setDB(e.target.result)
      let objectStore = idb.createObjectStore('files_os', { keyPath: 'id', autoIncrement:true });

      // Define what data items the objectStore will contain
      objectStore.createIndex('fileName', 'fileName', { unique: false });
      objectStore.createIndex('fileBlob', 'fileBlob', { unique: false });
  
      console.log('Database setup complete');
    };
  }

  const storeFileIdb = (file) => {
    // Open transaction, get object store; make it a readwrite so we can write to the IDB
    let objectStore = db.transaction(['files_os'], 'readwrite').objectStore('files_os');
    // Create a record to add to the IDB
    let record = {
      id:1,
      fileName : file.name,
      fileBlob : file
    }
  
    // Add the record to the IDB using add()
    let request = objectStore.put(record);

    request.onsuccess = function() {
      // Clear the form, ready for adding the next entry
      getFileIdb();
    };
  };

  const getFileIdb = () => {
    let objectStore = db.transaction('files_os').objectStore('files_os');
    let request = objectStore.get(1);
    request.onsuccess = function() {
      // If the result exists in the database (is not undefined)
      if(request.result) {
        // Grab the videos from IDB and display them using displayVideo()
        let imageObj = {};
        let imageURL = URL.createObjectURL(request.result.fileBlob).toString()
        imageObj.file = request.result
        imageObj.getUrl = () => imageURL
        imageObj.getFilename = () => request.result.fileBlob.name
        imageObj.getFilesize = () => request.result.fileBlob.size
        setFileLocal(imageObj);
      } else {
        // Fetch the videos from the network
        console.log('Not available :(');
      }
    };
  }

  const toggleFacingMode = () => {
    if(facingMode === 'environment') {
      setFacingMode('user')
    } else {
      setFacingMode('environment')
    }
  }
  useEffect(() => {
    setDemo(true)
    return () => {
      setDemo(false)
    }
  });

  useEffect(() => {
    initDb()
  },[]);

  useEffect(() => {
    if(demoFile){
      storeFileIdb(demoFile)
    }
  },[demoFile]);

  return (
    <MainCont>
      {!webcam && <>Upload an image or video    
      {!fileFromIdb && <Upload onFileChange={onFileChange}/> }
      Tag live objects from camera
      <PhotoCameraFrontIcon onClick={() => setWebcam(true)}/></>}
      {webcam && <FlipCameraIosIcon onClick={() => toggleFacingMode()}/>}
      {webcam && <Webcam facingMode={facingMode}/>}
      {/* {fileFromIdb && fileFromIdb.fileBlob.type.startsWith('image/') && <img src={URL.createObjectURL(fileFromIdb.fileBlob)} width="500"/>} */}
      {fileFromIdb && fileFromIdb.file.fileBlob.type.startsWith('image/') && <ImageDetail imageFile={fileFromIdb} />}
      {fileFromIdb && fileFromIdb.file.fileBlob.type.startsWith('video/') && <VideoPlayer url={URL.createObjectURL(fileFromIdb.file.fileBlob)}/>}
    </MainCont>
  );
}
export default Demo