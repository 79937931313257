import { styled } from '@mui/material/styles';

const MetaInfoCont = styled('div')(({ theme }) => ({
  width: '275px',
  maxHeight: '50vh',
  margin: '0px 20px 20px 20px',
  borderWidth: '5px',
  borderRadius: '5px',
  boxShadow: '0px 2px 4px #a9a9a9',
  padding: '12px'
}));


export default MetaInfoCont;