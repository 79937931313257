import React, { useEffect, useState, useRef } from 'react';
import { TextField, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import Table from './Table';
import { MainCont, Header, CreateCont } from './CommonCrud';
import { SpacesClient } from './proto/spaces_grpc_web_pb'
import { CreateSpaceRequest, ListSpacesRequest } from './proto/spaces_pb'
import useStore from "./loaderStore";

function Spaces() {
  const ariaLabel = { 'aria-label': 'description' };
  const [spaces, setSpaces] = useState([]);
  const [rows, setTableData] = useState([]);
  const [newSpaceName, setNewSpaceName] = useState("");
  const setLoading = useStore(state => state.setLoading);

  const spaceRef = useRef(null);

  const getSpaces = () => {
    setLoading(true)
    const req = new ListSpacesRequest();
    const client = new SpacesClient(process.env.REACT_APP_ENVOY_PROXY);
    client.listSpaces(req, {}, (err, resp) => {
      if (err) {
        console.log(err.code);
        console.log(err.message);
      } else {
        setSpaces(resp.getSpaceList())
        setLoading(false)
      }
    })
  }

  const createSpace = (name, spaceRef) => {
    setLoading(true)
    const req = new CreateSpaceRequest();
    const client = new SpacesClient(process.env.REACT_APP_ENVOY_PROXY);
    req.setName(name)
    client.createSpace(req, {}, (err, resp) => {
      if (err) {
        console.log(err.code);
        console.log(err.message);
      } else {
        getSpaces()
        setNewSpaceName("")
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    getSpaces()
  }, [])

  useEffect(() => {
    const result = []
    spaces.map(space => {
      result.push({ name: space.getName(), created: space.getCreateTime().toDate().toString(), updated: space.getUpdateTime().toDate().toString() })
    })
    setTableData(result)
  }, [spaces])

  return (
    <MainCont>
      <Header >
        Spaces
        <CreateCont>
          <TextField
            variant="standard"
            size="small"
            ref={spaceRef}
            placeholder="Create a new space"
            value={newSpaceName}
            inputProps={ariaLabel}
            onChange={e => setNewSpaceName(e.target.value)}
            onKeyPress={(ev) => {
              console.log(`Pressed keyCode ${ev.key}`);
              if (ev.key === 'Enter') {
                // Do code here
                createSpace(newSpaceName, spaceRef)
                ev.preventDefault();
              }
            }} />
          <IconButton
            color="secondary"
            aria-label="create space"
            onClick={() => createSpace(newSpaceName, spaceRef)}>
            <SaveIcon />
          </IconButton>
        </CreateCont>
      </Header>
      <Table rows={rows} />
    </MainCont>
  );
}
export default Spaces