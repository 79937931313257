import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Pagination from '@mui/material/Pagination';
import React, { useEffect, useState } from 'react';
import useStore from "./loaderStore";
import { ImagesClient } from './proto/images_grpc_web_pb';
import { ListImagesRequest } from './proto/images_pb';
import { formatBytes } from './utils/bytes';
import { styled } from '@mui/material/styles';
import {
  Link
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

const MainCont = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '5px',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  overflow: 'hidden',
}));

const Image = styled('img')(({ theme }) => ({
  height: '160px',
  objectFit: 'contain'
}));

const Title = styled('h5')(({ theme }) => ({
  alignSelf: 'center'
}));

const GridList = styled(ImageList)(({ theme }) => ({
  height: '70vh',
  width: '80vw',
}));

function Images() {
  const classes = useStyles();
  const [images, setImages] = useState([]);
  const [pages, setPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [size, setListSize] = useState(12);
  const setLoading = useStore(state => state.setLoading);

  const getImages = ({ currentPage }) => {
    var request = new ListImagesRequest();
    request.setSize(size)
    request.setOffset(currentPage - 1)
    const client = new ImagesClient(process.env.REACT_APP_ENVOY_PROXY);
    client.listImages(request, {}, function (err, response) {
      if (err) {
        console.log(err.code);
        console.log(err.message);
        setLoading(false)
      } else {
        setImages(response.getImageList());
        setPages(response.getPages());
        setTotalCount(response.getTotalcount());
        setLoading(false)
      }
    });
  }

  useEffect(() => {
    setLoading(true)
    getImages({ currentPage });
  }, [currentPage]);

  return (
    <MainCont>
      <Title >Images - {(currentPage - 1) * size + 1} to {(currentPage - 1) * size + size > totalCount ? totalCount : (currentPage - 1) * size + size} of {totalCount}</Title>
      <GridList gap={8} rowHeight={160} cols={4}>
        {images.map((image) => (
          <ImageListItem key={image.getId()}>
            <Image
              src={image.getUrl()}
              alt={image.getId()}
            />
            <ImageListItemBar
              title={image.getId()}
              subtitle={<span>size: {formatBytes(image.getFilesize())}</span>}
              actionIcon={
                <IconButton aria-label={`info about ${image.getId()}`} className={classes.icon}>
                  <Link to={`/images/${image.getId()}`}><EditIcon /></Link>
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </GridList>
      <Pagination color="secondary" count={pages} size="large" page={currentPage} onChange={(e, page) => { setCurrentPage(page) }} />
    </MainCont>
  );
}
export default Images