import React, { useEffect, useRef, useState } from 'react';
import { load } from '@tensorflow-models/mobilenet';
import { create } from '@tensorflow-models/knn-classifier';
import * as tf from '@tensorflow/tfjs';
import { CreateCont } from './CommonCrud';
import { TextField, IconButton, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const LabelCont = styled('div')(({ theme }) => ({
  maxWidth: '200px'
}));


export default function Webcam({ facingMode }) {
  const webcamRef = useRef(null);
  const [startInference, setStartInference] = useState(false)
  const [webCam, setWebcam] = useState(null)
  const [model, setModel] = useState(null)
  const [classifier, setClassifier] = useState(null)
  const [newObjClsName, setObjClsName] = useState("");
  const objClsRef = useRef(null);
  const [classes, setClasses] = useState([])
  const createObjClass = (name, objClsRef) => {
    setClasses([...classes, name])
    setObjClsName("")
  }

  const addExample = async classId => {
    console.log('Adding example', classId);
    if (webCam && model) {
      // Capture an image from the web camera.
      const img = await webCam.capture();

      // Get the intermediate activation of MobileNet 'conv_preds' and pass that
      // to the KNN classifier.
      const activation = model.infer(img, true);

      // Pass the intermediate activation to the classifier.
      classifier.addExample(activation, classId);

      // Dispose the tensor to release the memory.
      img.dispose();
    }
  };

  useEffect(() => {
    console.log('Loading mobilenet..');
    // Load the model.
    let webcam;
    load()
      .then(async (model) => {
        console.log('Successfully loaded model');
        webcam = await tf.data.webcam(webcamRef.current, { facingMode });
        setWebcam(webcam)
        setModel(model)
        const clfr = await create();
        setClassifier(clfr)
        let img;
        while (true) {
          if (clfr && clfr.getNumClasses() > 0) {
            // console.log('clfr', clfr.getNumClasses())
            img = await webcam.capture();
            // Get the activation from mobilenet from the webcam.
            const activation = model.infer(img, 'conv_preds');
            // Get the most likely class and confidence from the classifier module.
            const result = await clfr.predictClass(activation);

            document.getElementById('console').innerText = `
                prediction: ${classes[result.label]}\n
                probability: ${result.confidences[result.label]}
              `;

            // Dispose the tensor to release the memory.
            img.dispose();
          }
          await tf.nextFrame();
        }
      })
      return function cleanup() {
        webcam.stop();
      };
  
  }, [facingMode, startInference])

  return (
    <>
      <IconButton
        color="secondary"
        aria-label="create Team"
        onClick={() => createObjClass(newObjClsName, objClsRef)}
      >
        <VisibilityIcon onClick={() => {setStartInference(!startInference)}} />
      </IconButton>
      <video autoPlay playsInline muted id="webcam" width="224" height="224" ref={webcamRef}></video>
     <LabelCont>
      {classes.length > 0 && classes.map(cls => {
        return <Button id={`class-${classes.indexOf(cls)}`} onClick={() => addExample(classes.indexOf(cls))}>{cls}</Button>
      })}
      </LabelCont>
      <CreateCont>
        <TextField
          variant="standard"
          size="small"
          ref={objClsRef}
          placeholder="New class"
          value={newObjClsName}
          onChange={e => setObjClsName(e.target.value)}
          onKeyPress={(ev) => {
            console.log(`Pressed keyCode ${ev.key}`);
            if (ev.key === 'Enter') {
              // Do code here
              createObjClass(newObjClsName, objClsRef)
              ev.preventDefault();
            }
          }} />
        <IconButton
          color="secondary"
          aria-label="create Team"
          onClick={() => createObjClass(newObjClsName, objClsRef)}
        >
          <SaveIcon />
        </IconButton>
      </CreateCont>
      <div id="console"></div>
    </>
  );
}