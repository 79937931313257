import React, {useRef} from 'react';
import Canvas from "./Canvas";
import { styled } from '@mui/material/styles';

const Cont = styled('div')(({ theme }) => ({
  width: '80vh'
}));

const ImageDetailKonva =  ({zoomState, image}) => {
  const imgContRef = useRef(null);
  return (
    <Cont ref={imgContRef} >
      <Canvas image={image} zoomState={zoomState} imgContRef={imgContRef}/>
    </Cont>
  );
};
export default ImageDetailKonva