import React, { useEffect, useState, useRef } from 'react';
import { TextField, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import Table from './Table';
import { MainCont, Header, CreateCont } from './CommonCrud';
import { LabelsClient } from './proto/labels_grpc_web_pb'
import { CreateLabelRequest, ListLabelsRequest } from './proto/labels_pb'
import loaderStore from "./loaderStore";

function Labels() {
  const ariaLabel = { 'aria-label': 'description' };
  const [labels, setLabels] = useState([]);
  const [rows, setTableData] = useState([]);
  const [newLabelName, setNewLabelName] = useState("");
  const setLoading = loaderStore(state => state.setLoading);

  const labelRef = useRef(null);

  const getLabels = () => {
    setLoading(true)
    const req = new ListLabelsRequest();
    const client = new LabelsClient(process.env.REACT_APP_ENVOY_PROXY);
    client.listLabels(req, {}, (err, resp) => {
      if (err) {
        console.log(err.code);
        console.log(err.message);
      } else {
        setLabels(resp.getLabelList())
        setLoading(false)
      }
    })
  }
  
  const createLabel = (name, labelRef) => {
    setLoading(true)
    const req = new CreateLabelRequest();
    const client = new LabelsClient(process.env.REACT_APP_ENVOY_PROXY);
    req.setName(name)
    client.createLabel(req, {}, (err, resp) => {
      if (err) {
        console.log(err.code);
        console.log(err.message);
      } else {
        getLabels()
        setNewLabelName("")
        setLoading(false)
      }
    })
  }
  
  useEffect(() => {
    getLabels()
  },[])
  
  useEffect(() => {
    const result = []
    labels.map(prj => {
      result.push({name: prj.getName(), created: prj.getCreateTime().toDate().toString(), updated: prj.getUpdateTime().toDate().toString()})
    })
    setTableData(result)
  },[labels])

  return (
    <MainCont>
      <Header >
        Labels manager
        <CreateCont>
          <TextField variant="standard" size="small" ref={labelRef} placeholder="Create a new Label" value={newLabelName} inputProps={ariaLabel} onChange={e => setNewLabelName(e.target.value)} 
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                // Do code here
                createLabel(newLabelName, labelRef)
                ev.preventDefault();
              }
            }}/>
          <IconButton color="secondary" aria-label="create Label" onClick={() => createLabel(newLabelName, labelRef)}>
            <SaveIcon />
          </IconButton>
        </CreateCont>
      </Header>
      <Table rows={rows}/>
    </MainCont>
  );
}
export default Labels