import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import AppBar from './AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Router from './Router';
import Container from '@mui/material/Container';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import GroupsIcon from '@mui/icons-material/Groups';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import {
  Link
} from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const MenuLink = styled(Link)(({ theme, src }) => ({
  all: 'unset',
  display: 'flex'
}));

const Icons = {
  'Home': { icon: <HomeWorkIcon />, link: '/'},
  'Images': { icon: <PhotoLibraryIcon />, link: '/images'},
  'Videos': { icon: <VideoCameraBackIcon />, link: '/videos'},
  'Spaces': { icon: <WorkspacesIcon />, link: '/spaces'},
  'Projects': { icon: <CollectionsBookmarkIcon />, link: '/projects'},
  'Labels': { icon: <LocalOfferIcon />, link: '/labels'},
  'Settings': { icon: <SettingsIcon />, link: '/settings'},
  'Teams': { icon: <GroupsIcon />, link: '/teams'},
  'Demo': { icon: <AllInclusiveIcon />, link: '/play'},
  'Login': { icon: <LoginIcon />, link: '/login'},
  'Logout': { icon: <LogoutIcon />, link: '/logout'}
}

export default function MiniDrawer(props) {
  const theme = useTheme();

  const { show, toggleDrawer } = props;
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar show={show} toggleDrawer={toggleDrawer} />
      <Drawer variant="permanent" open={show} >
        <DrawerHeader>
          <IconButton onClick={(e) => toggleDrawer(e, false)}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {['Home', 'Demo'].map((text, index) => (
            <MenuLink to={Icons[text].link} key={text+index}>
              <Tooltip title={text} placement="right">
                <ListItem button key={text}>
                  <ListItemIcon>
                    {Icons[text].icon}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              </Tooltip>
            </MenuLink>
          ))}
        </List>
        <Divider />
        {/* <List>
          {['Settings', 'Logout', 'Login'].map((text, index) => (
            <MenuLink to={Icons[text].link} key={text+index}>
              <Tooltip title={text} placement="right">
                <ListItem button key={text}>
                  <ListItemIcon>
                    {Icons[text].icon}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              </Tooltip>
            </MenuLink>
          ))}
        </List> */}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Container maxWidth="false">
          <Router />
        </Container>
      </Box>
    </Box>
  );
}
