import React from 'react';
import RegionsList from "./RegionsList";
import { formatBytes } from './utils/bytes';
import { styled } from '@mui/material/styles';
import MetaInfoCont from './MetaInfoCont';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
  Redirect
} from "react-router-dom";

const Metatitle = styled('b')(({ theme }) => ({
  left: '20px'
}));

const MetaCont = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}));

const Metalist = styled('ul')(({ theme }) => ({
  paddingTop: '10px',
  borderTop: '1px solid #d3d3d3',
  boxShadow: '0px 2px 4px #a9a9a9',
}));


const ImageMatadata = ({image}) => {
  return (
    <MetaCont>
      <MetaInfoCont>
        <Metatitle>Image info</Metatitle>
        <Metalist>
          <li><b>Name:</b> {image.getFilename()}</li>
          <li><b>Size:</b> {formatBytes(image.getFilesize())}</li>
          <li><b>Download:</b><a href={image.getUrl()} download="demo.jpg"><CloudDownloadIcon /></a>
          </li>
        </Metalist>
      </MetaInfoCont>
      <RegionsList />
    </MetaCont>
  );
}
export default ImageMatadata