import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PauseIcon from '@mui/icons-material/Pause';
import BoltIcon from '@mui/icons-material/Bolt';
import DownloadIcon from '@mui/icons-material/Download';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PublishIcon from '@mui/icons-material/Publish';
import videoStore from "./videoStore";
import screenfull from 'screenfull'
import { Box, Paper, Fade, Tooltip, Popper, Typography } from '@mui/material';
import Slider, { SliderThumb } from "@mui/material/Slider";
import loaderStore from "./loaderStore";
import demoStore from "./demoStore";
import Logo from './images/logo.svg';
import ClipView from './ClipView';
import VideoLabelDialog from './VideoLabelDialog';
import { saveJsonAsFile, fileToJSON } from './utils/json';
import {
  useParams
} from "react-router-dom";
import { Input } from './CommonCrud';

const HomeIcon = styled('img')(({ theme, src }) => ({
  height: '15px'
}));

const Controls = styled('div')(({ theme }) => ({
  width: '65vw',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  background: theme.palette.secondary,
}));


const Main = styled('div')(({ theme }) => ({
  width: '65vw',
}));

const ToolIcon = styled('div')(({ theme }) => ({
  minWidth: '40px',
  padding: '4px',
  color: theme.palette.primary.dark,
  cursor: 'pointer'
}));

const PlayerControls = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row'
}));

const VolSlider = styled('input')(({ theme }) => ({
  margin: '4px',
  width: '40px',
  '-webkit-appearance': 'slider-vertical'
}));

const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});

const ThumbLine = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '22vh',
  width: 1,
  background: '#ff0000',
  marginLeft: 1,
  marginTop: -4,
  marginRight: 1,
  left: '9px',
}));

const ThumbDiv = styled(SliderThumb)(({ theme }) => ({
  display: 'block',
  flexDirection: 'column',
  zIndex: 1
}));

function ChupuThumbComponent(props) {
  const sliderRef = useRef();
  const tagClips = videoStore(state => state.tagClips);

  const { children, ...other } = props;
  const setSliderThumb = videoStore(state => state.setSliderThumb);
  useEffect(() => {
    setSliderThumb(sliderRef)
  })
  return (
    <ThumbDiv {...other} ref={sliderRef}>
        {children}
        <HomeIcon src={Logo} />
        {Object.keys(tagClips).length > 0 && <ThumbLine />}
    </ThumbDiv>
    );
}

function VideoControls({ playerRef }) {
  let { id } = useParams();
  const setLoading = loaderStore(state => state.setLoading);
  const playing = videoStore(state => state.playing);
  const tempPlayState = videoStore(state => state.tempPlayState);
  const setTempPlayState = videoStore(state => state.setTempPlayState);
  const setPlaying = videoStore(state => state.setPlaying);
  const setVolume = videoStore(state => state.setVolume);
  const ready = videoStore(state => state.ready);
  const dialogOpen = videoStore(state => state.dialogOpen);
  const position = videoStore(state => state.position);
  const played = videoStore(state => state.played);
  const onProgress = videoStore(state => state.onProgress);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [duration, setDuration] = React.useState(0);
  const setDialogOpen = videoStore(state => state.setDialogOpen);
  const tagClips = videoStore(state => state.tagClips);
  const updateTagClips = videoStore(state => state.updateTagClips);
  const demo = demoStore(state => state.demo);

  const formatDuration = (value) => {
    const minute = Math.floor(value / 60);
    const secondLeft = Math.floor(value - minute * 60);
    return `${minute}:${secondLeft < 9 ? `0${secondLeft}` : secondLeft}`
  }
  const handleClickFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.request(playerRef.current.wrapper);
    }
  };
  const handleVol = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const onSeek = ({ value, duration }) => {
    setTempPlayState(playing)
    setPlaying(false)
    onProgress({ played: value, playedSeconds: value * duration})
  }

  const onSeekEnd = ({ value }) => {
    playerRef.current.seekTo(value)
    setPlaying(tempPlayState)
    setTempPlayState(null)
  }

  const downloadAnno = () => {
    saveJsonAsFile(id.split('.').slice(0, -1).join('.')+'.json', tagClips)
  }
  
  const uploadAnno = async (e) => {
    console.log('File has changed!!!', e)
    const annos = await fileToJSON(e.target.files[0])
    updateTagClips(annos)
  }

  const persistAnno = () => {

  }

  const getNextFrame = ({position, intent}) => {
    const frameRate = 24;
    const curFrame = Math.floor(position * frameRate)
    const nextTimeFrame = curFrame + intent;
    return nextTimeFrame / frameRate
  }
  const setPrevFrame = ({ played, duration }) => {
    const nextFrameSec = getNextFrame({position: played* duration, intent: -1}) / duration
    playerRef.current.seekTo(nextFrameSec)
  }
  const setNextFrame = ({ played, duration }) => {
    const nextFrameSec = getNextFrame({position: played * duration, intent: 2}) / duration
    playerRef.current.seekTo(nextFrameSec)
  }

  useEffect(() => {
    if (ready) {
      setDuration(playerRef.current.getDuration());
      setLoading(false);
    }
  }, [ready])

  useEffect(() => {
    if(dialogOpen){
      setPlaying(false)
    }else {
      // setPlaying(true)
    }
  }, [dialogOpen])


  return (
    <Main>
      <Popper open={open} anchorEl={anchorEl} placement={'top'} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper >
              <VolSlider
                id="slider"
                type="range"
                min="0"
                max="1"
                step="0.005"
                defaultValue="0.25"
                onChange={e => {
                  setVolume(e.target.value)
                }}
              />
            </Paper>
          </Fade>
        )}
      </Popper>
      <Controls>
        <Tooltip title="volume" placement="bottom" >
          <ToolIcon onClick={handleVol}>
            <VolumeUpIcon fontSize="large" />
          </ToolIcon>
        </Tooltip>
        <PlayerControls>
          <Tooltip title="prev 1 sec" placement="bottom">
            <ToolIcon>
              <FastRewindIcon fontSize="large" onClick={() => setPrevFrame({played, duration})}/>
            </ToolIcon>
          </Tooltip>
          <Tooltip title="prev frame" placement="bottom">
            <ToolIcon>
              <SkipPreviousIcon fontSize="large" onClick={() => setPrevFrame({played, duration})}/>
            </ToolIcon>
          </Tooltip>
          <Tooltip title="play" placement="bottom">
            <ToolIcon>
              {!playing && <PlayArrowIcon fontSize="large" onClick={() => setPlaying(!playing)} />}
              {playing && <PauseIcon fontSize="large" onClick={() => setPlaying(!playing)} />}
            </ToolIcon>
          </Tooltip>
          <Tooltip title="skip frame" placement="bottom">
            <ToolIcon>
              <SkipNextIcon fontSize="large" onClick={() => setNextFrame({played, duration})}/>
            </ToolIcon>
          </Tooltip>
          <Tooltip title="skip 1 sec" placement="bottom">
            <ToolIcon>
              <FastForwardIcon fontSize="large" onClick={() => setPrevFrame({played, duration})}/>
            </ToolIcon>
          </Tooltip>
        </PlayerControls>
        <Tooltip title="label" placement="bottom">
          <ToolIcon>
            <BoltIcon fontSize="large" onClick={e => setDialogOpen(true)} />
          </ToolIcon>
        </Tooltip>
        <Tooltip title="import annotations" placement="bottom">
          <ToolIcon>
          <label htmlFor="icon-button-file1">
            <Input accept="application/JSON" id="icon-button-file1" type="file" onChange={uploadAnno} />
              <PublishIcon aria-label="upload picture" fontSize="large"/>
          </label>
          </ToolIcon>
        </Tooltip>
        <Tooltip title="export annotations" placement="bottom">
          <ToolIcon>
            <DownloadIcon fontSize="large" onClick={e => downloadAnno(id)} />
          </ToolIcon>
        </Tooltip>
        {!demo && 
          <Tooltip title="persist annotations" placement="bottom">
            <ToolIcon>
              <CloudUploadIcon fontSize="large" onClick={e => persistAnno()} />
            </ToolIcon>
          </Tooltip>      
        }
        <Tooltip title="fullscreen" placement="bottom">
          <ToolIcon>
            <FullscreenIcon fontSize="large" onClick={handleClickFullscreen} />
          </ToolIcon>
        </Tooltip>
      </Controls>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 1,
        }}
      >
        <TinyText>{formatDuration(position)}</TinyText>
        <TinyText>-{formatDuration(duration - position)}</TinyText>
      </Box>

      <Slider
        color="secondary"
        aria-label="time-indicator"
        value={played}
        defaultValue={played}
        step={0.001}
        min={0}
        max={1}
        components={{ Thumb: ChupuThumbComponent }}
        // marks
        onChange={(_, value) => onSeek({ value, duration })}
        onChangeCommitted={(_, value) => onSeekEnd({ value })}
      />
      <ClipView playerRef={playerRef} />
      <VideoLabelDialog />
    </Main>
  );
}

export default VideoControls
