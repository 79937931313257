import React, { useState, useEffect } from 'react';
import {VideosClient} from './proto/videos_grpc_web_pb';
import {GetVideoDetailRequest} from './proto/videos_pb';
import {
  useParams
} from "react-router-dom";
import useStore from "./loaderStore";
import Loader from './Loader';
import VideoPlayer from './VideoPlayer';
import { styled } from '@mui/material/styles';

const MainCont = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

function VideoDetail() {

  let { id } = useParams();
  const [vidUrl, setVidUrl] = useState("");
  const loading = useStore(state => state.loading);
  const setLoading = useStore(state => state.setLoading);

  const handleClose = (value) => {
    setLoading(false)
  };
  
  const getVideo = () => {
    var request = new GetVideoDetailRequest();
    const client = new VideosClient(process.env.REACT_APP_ENVOY_PROXY);
    request.setKey(id)
    client.getVideoDetails(request, {}, function (err, response) {
      if (err) {
        console.log(err.code);
        console.log(err.message);
        setLoading(false)
      } else {
        setVidUrl(response.getUrl());
      }
    });
  }
  
  useEffect(() => {
    setLoading(true)
    getVideo();
  },[]);

  return (
    <MainCont> 
      <VideoPlayer url={vidUrl} />
      <Loader
        open={loading}
        onClose={handleClose}
      />
    </MainCont>
  );
}
export default VideoDetail
