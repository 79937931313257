import React, { useEffect, useRef, useState } from 'react';
import { Stage } from 'react-konva';
import BaseImage from './BaseImage';
import Box from './Box';
import DetectedObjects from './DetectedObjects';
import DetectedPose from './DetectedPose';
import DetectedSegments from './DetectedSegments';
import Draw from './Draw';
import { ImgDetailContext } from './ImageDetailsContext';
import Polygon from './Polygon';
import Regions from './Regions';
import useStore from './store';

import { limitAttributes } from './utils/mouse'
// import ThreeDBox from './ThreeDBox'; 

function zoomStage(stage, scaleBy) {
  const oldScale = stage.scaleX();

  const pos = {
    x: stage.width(),
    y: stage.height()
  };
  const mousePointTo = {
    x: pos.x / oldScale - stage.x() / oldScale,
    y: pos.y / oldScale - stage.y() / oldScale
  };

  const newScale = Math.max(0.05, oldScale * scaleBy);

  const newPos = {
    x: -(mousePointTo.x - pos.x / newScale) * newScale,
    y: -(mousePointTo.y - pos.y / newScale) * newScale
  };

  const newAttrs = limitAttributes(stage, { ...newPos, scale: newScale });

  stage.to({
    x: newAttrs.x,
    y: newAttrs.y,
    scaleX: newAttrs.scale,
    scaleY: newAttrs.scale,
    duration: 0.1
  });
  stage.batchDraw();
}

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Canvas = ({image, imgContRef, zoomState}) => {

  const stageRef = useRef();
  const { width, height } = useStore(s => ({
    width: s.width,
    height: s.height
  }));
  const setSize = useStore(s => s.setSize);
  const scale = useStore(state => state.scale);
  const onClick = useStore(s => s.onClick);
  const onWheel = useStore(s => s.onWheel);
  const onMouseDown = useStore(s => s.onMouseDown);
  const onMouseMove = useStore(s => s.onMouseMove);
  const onMouseUp = useStore(s => s.onMouseUp);
  const regionId = useStore(state => state.regionId);
  const isDrawing = useStore(state => state.isDrawing);
  const polygonPoints = useStore(state => state.polygonPoints);
  const regions = useStore(state => state.regions);
  const isMouseOverStartPoint = useStore(state => state.isMouseOverStartPoint);
  const imgRef = useStore(state => state.imgRef);
  const mouseParams = e => {
    return {
      e,
      isDrawing,
      regionId,
      regions,
      polygonPoints,
      isMouseOverStartPoint,
      imgRef, 
      scale   
    }
  };

  useEffect(() => {
    function checkSize() {
      const container = imgContRef;
      setSize({
        width: container.current.offsetWidth,
        height
      });
    }
    checkSize();
    window.addEventListener('resize', checkSize);
    return () => window.removeEventListener('resize', checkSize);
  }, []);

  const prevZoomstate = usePrevious(zoomState);

  useEffect(() => {
    if (zoomState !== 0) {
      if (prevZoomstate < zoomState) {
        // process here
        zoomStage(stageRef.current, 1.2);
      } else {
        zoomStage(stageRef.current, 0.8);
      }
    }
  }, [prevZoomstate, zoomState]);

  const [imageRef, setBaseImageRef] = useState(null);
  
  // useEffect(() => {
  //   console.log('Canvas Logging!!!', imgRef)
  // }, [imgRef])

  return (
    <ImgDetailContext.Consumer>
      {({ selectedTool, selectedLayer, setLoading, setLayerLoadStart, setLayerLoadEnd, showSnack }) => (
        <>
        <Stage
          ref={stageRef}
          width={width}
          height={height}
          scaleX={scale}
          scaleY={scale}
          className="canvas"
          onClick={onClick}
          onWheel={onWheel}
          onMouseDown={e => onMouseDown(mouseParams(e))}
          onMouseMove={e => onMouseMove(mouseParams(e))}
          onMouseUp={e => onMouseUp(mouseParams(e))}
        >
          <BaseImage image={image} stageRef={stageRef} ref={imageRef} setBaseImageRef={setBaseImageRef} />
          {selectedTool && selectedTool === 'draw' && <Draw stageRef={stageRef} />}
          {selectedTool && selectedTool === 'box' && <Box stageRef={stageRef} imgRef={imgRef}/>}
          {selectedTool && selectedTool === 'polygon' && <Polygon stageRef={stageRef} polygonPoints={polygonPoints} />}
          {selectedLayer && selectedLayer === 'objectDetect' && <DetectedObjects image={image} imageRef={imageRef} stageRef={stageRef} setLoading={setLoading} setLayerLoadStart={setLayerLoadStart} setLayerLoadEnd={setLayerLoadEnd} showSnack={showSnack} />}
          {selectedLayer && selectedLayer === 'poseDetect' && <DetectedPose image={image} imageRef={imageRef} stageRef={stageRef} setLoading={setLoading} setLayerLoadStart={setLayerLoadStart} setLayerLoadEnd={setLayerLoadEnd} showSnack={showSnack} />}
          { selectedLayer && selectedLayer === 'segmentDetect' && <DetectedSegments image={image} imageRef={imageRef} stageRef={stageRef} setLoading={setLoading}/>}
          <Regions />
        </Stage>
        {/* <ThreeDBox image={image}/> */}
        </>
      )}
    </ImgDetailContext.Consumer>
  );
};

export default Canvas