import create from "zustand";

const [useStore] = create(set => ({
  width: window.innerWidth,
  height: window.innerHeight,
  setSize: ({ width, height }) => set({ width, height }),

  imageWidth: 100,
  imageHeight: 100,
   
  setImageSize: size => set(() => ({ imageWidth: size.width, imageHeight: size.height })),
  
  scale: 1,
  setScale: scale => set({ scale }),

  scaleFactor: 1 / window.devicePixelRatio,
  setScaleFactor: scaleFactor => set({ scaleFactor }),
  
  regionId: 1,
  setRegionId: (val) => set(state => ({ regionId: val })),
  
  isDrawing: false,
  toggleIsDrawing: (val) => set(state => ({ isDrawing: val })),

  regions: [],
  setRegions: regions => set(() => ({ regions })),

  polygonPoints: [],
  setPolygonPoints: polygonPoints => set(() => ({ polygonPoints })),

  isMouseOverStartPoint: false,
  setIsmouseOver: isMouseOverStartPoint => set(() => ({ isMouseOverStartPoint })),

  selectedRigionId: null,
  selectRegion: selectedRigionId => set({ selectedRigionId }),

  brightness: 0,

  // Tool events
  setBrightness: brightness => set({ brightness }),
  onClick: () => {},
  setOnClick: onClick => set(state => ({ onClick })),
  onWheel: () => {},
  setOnWheel: onWheel => set(state => ({ onWheel })),
  onMouseDown: () => {},
  setOnMouseDown: onMouseDown => set(state => ({ onMouseDown })),
  onMouseMove: () => {},
  setOnMouseMove: onMouseMove => set(state => ({ onMouseMove })),
  onMouseUp: () => {},
  setOnMouseUp: onMouseUp => set(state => ({ onMouseUp })),

  cropped: null,
  setCropped: cropped => set(state => ({ cropped })),
  imgRef: () => {},
  setImgRef: imgRef => set(state => ({ imgRef })),
}));

export default useStore;
