import React from 'react';
import {  Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

function Signup() {
  const classes = useStyles();
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField id="standard-basic" label="First Name" /><br/>
      <TextField id="standard-basic" label="Last Name" /><br/>
      <TextField id="standard-basic" label="Email" /><br/>
      <Button variant="outlined" color="primary">
        Sign Up
      </Button>
    </form>
  );
}
export default Signup