import * as React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Logo from './images/logo.svg'
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const HomeIcon = styled('div')(({ theme }) => ({
    width: '75px',
    margin: '20px'
}));

const LoaderDialog = styled(Dialog)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    overflow: 'visible',
    '& .MuiPaper-root': {
        borderRadius: '10px'
    }
}));

function Loader(props) {
    const { onClose, open } = props;
    const handleClose = () => {
        onClose();
    };

    return (
        <LoaderDialog onClose={handleClose} open={open}>
            <LinearProgress color="secondary"/>
            <HomeIcon ><img src={Logo} alt="loading.."/></HomeIcon>
            {/* <DialogTitle></DialogTitle> */}
            
        </LoaderDialog>
    );
}

Loader.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};
export default Loader;
