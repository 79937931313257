import React, { useEffect, useState } from "react";
import useImage from "use-image";
import { Layer } from "react-konva";
import { load } from '@tensorflow-models/deeplab';


// const deeplabExampleImages = {
//   pascal: pascalExampleImage,
//   cityscapes: cityscapesExampleImage,
//   ade20k: ade20kExampleImage,
// };

const initializeModels = async () => {
  let deeplab = [];
  let res = {};
  const modelNames = ['pascal', 'cityscapes', 'ade20k'];
  const quantizationBytes = 4;
  modelNames.forEach((base) => {
    deeplab.push(load({base, quantizationBytes}));
  });
  let ffd = await Promise.all(deeplab);
  ffd.forEach(model => {
    res[model['base']] = model;
  })
  return res;
};


const DetectedSegments = (props) => {
  const [image] = useImage(props.image, "Anonymous");
  const layerRef = React.useRef(null);
  const [deeplab, setDL] = useState(null)
    
  const displaySegmentationMap = (deeplabOutput) => {
    console.log('DL OP', deeplabOutput)
    const {legend, height, width, segmentationMap} = deeplabOutput;
    const segmentationMapData = new ImageData(segmentationMap, width, height);
    var canvas = layerRef.current.getCanvas()._canvas;
    const ctx = canvas.getContext('2d');
    // canvas.style.width = '100%';
    // canvas.style.height = '100%';
    canvas.width = width;
    canvas.height = height ;
    ctx.putImageData(segmentationMapData, 0, 0);
  };
  
  useEffect(() => {
    if(!image){
      return
    }
    initializeModels()
      .then(setDL)

  }, [image]);

  useEffect(() => {
    if(deeplab){
      deeplab['cityscapes'].segment(image)
      .then(output => {
        displaySegmentationMap(output)
      })
    }
  }, [deeplab]);

  return (
    <Layer ref={layerRef} opacity={0.5}/>
  );
};

export default DetectedSegments