import React, { useState, useEffect } from 'react';
import { formatBytes } from './utils/bytes';
import { VideosClient } from './proto/videos_grpc_web_pb';
import { ListVideosRequest } from './proto/videos_pb';
import useStore from "./loaderStore";
import { makeStyles } from '@mui/styles';
import {Table, TableBody, TableCell,TableContainer, TableHead, TableRow, Paper, ListSubheader} from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {
  Link
} from "react-router-dom";
import { styled } from '@mui/material/styles';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function Videos() {
  const classes = useStyles();
  const [vids, setVideos] = useState([]);

  const setLoading = useStore(state => state.setLoading);

  const getVideos = () => {
    var request = new ListVideosRequest();
    const client = new VideosClient(process.env.REACT_APP_ENVOY_PROXY);
    client.listVideos(request, {}, function (err, response) {
      if (err) {
        console.log(err.code);
        console.log(err.message);
        setLoading(false)
      } else {
        setVideos(response.getVideoList());
        setLoading(false)
      }
    });
  }

  useEffect(() => {
    setLoading(true)
    getVideos();
  }, []);
  let rows = []

  if (vids.length > 0) {
    rows = vids.map(vid => {
      return {
        id: vid.getId(),
        key: vid.getId(),
        size: formatBytes(vid.getFilesize()),
        created: Date(vid.getCreateTime())
      }
    })
  }

  return (
    <>
    <>
      <ListSubheader component="div">Videos List</ListSubheader>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Size</TableCell>
              <TableCell align="right">Created</TableCell>
              <TableCell align="right">Tag</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.key}
                </TableCell>
                <TableCell align="right">{row.size}</TableCell>
                <TableCell align="right">{row.created}</TableCell>
                <TableCell align="right">
                  <Link to={`/videos/${row.key}`}>
                    <PlayCircleOutlineIcon />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
    {/* Videos
    <VideosCont>
      {rows.map((row) => (
        <Card />
      ))}
    </VideosCont> */}
    </>
  );
}
export default Videos