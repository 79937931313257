import React, { useEffect, useState, useRef } from 'react';
import Konva from 'konva';
import useImage from "use-image";
import { Layer } from "react-konva";
import loaderStore from "./loaderStore";
import useStore from './store';

require('@tensorflow/tfjs-backend-cpu');
// require('@tensorflow/tfjs-backend-webgl');
const cocoSsd = require('@tensorflow-models/coco-ssd');

const DetectedObjects = (props) => {
  const scale = useStore(state => state.scaleFactor);
  const setLoading = loaderStore(state => state.setLoading);
  
  const [image] = useImage(props.image, "Anonymous");
  const { stageRef, setLayerLoadStart, setLayerLoadEnd, showSnack, imageRef} = props;

  const layerRef = useRef(null);
  const [imgObjs, setImgObjects] = useState(null);
  


  const spawnRectangle = (x, y, width, height, text) => {
    x = x * scale
    y = y * scale
    height = height * scale
    width = width * scale

    var rectangle = new Konva.Group({
      x: x,
      y: y,
      width: width,
      height: height,
    });

    rectangle.add(new Konva.Rect({
      width: width,
      height: height,
      fill: Konva.Util.getRandomColor(),
      opacity: 0.5
    }));

    rectangle.add(new Konva.Text({
      text: text,
      fontSize: 6,
      fontFamily: 'Roboto',
      fill: '#fff',
      width: width,
      padding: 5,
      align: 'center'
    }));

    return rectangle;
  }
  useEffect(() => {
    if (!imgObjs) {
      return
    }

    imgObjs.map((imObj) => {
      const transform = layerRef.current.getAbsoluteTransform().copy();

      // to detect relative position we need to invert transform
      transform.invert();

      // get pointer (say mouse or touch) position
      const p1 = { x: imObj.bbox[0], y: imObj.bbox[1] }
      const p2 = { x: imObj.bbox[0] + imObj.bbox[2], y: imObj.bbox[1] }
      const p3 = { x: imObj.bbox[0] + imObj.bbox[2], y: imObj.bbox[1] + imObj.bbox[3] }

      // now we find relative point
      const np1 = transform.point(p1);
      const np2 = transform.point(p2);
      const np3 = transform.point(p3);

      var rect = spawnRectangle(np1.x, np1.y, (np2.x - np1.x), (np3.y - np1.y), imObj.class)

      layerRef.current.add(rect);
      return null
    })

    // Add this layer to stage so it can render
    stageRef.current.add(layerRef.current)
    setLoading(false)
    setLayerLoadEnd(Date.now())
    showSnack(true)   
  }, [imgObjs, stageRef, setLoading]);

  const getPreds = async (canvas) => {
    // Load the model.
    const model = await cocoSsd.load();
    return await model.detect(canvas);
  }

  useEffect(() => {
    if (!image || !imageRef.current) {
      return
    }
    setLoading(true)
    setLayerLoadStart(Date.now())
    const canvas = props.imageRef.current.getCanvas()._canvas;

    // Classify the image.
    getPreds(canvas)
      .then(setImgObjects)
  }, [image]);


  return (
    <Layer ref={layerRef} />
  );
};

export default DetectedObjects