/**
 * @fileoverview gRPC-Web generated client stub for chupu
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.chupu = require('./videos_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.chupu.VideosClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.chupu.VideosPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chupu.ListVideosRequest,
 *   !proto.chupu.ListVideosReponse>}
 */
const methodDescriptor_Videos_ListVideos = new grpc.web.MethodDescriptor(
  '/chupu.Videos/ListVideos',
  grpc.web.MethodType.UNARY,
  proto.chupu.ListVideosRequest,
  proto.chupu.ListVideosReponse,
  /**
   * @param {!proto.chupu.ListVideosRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.ListVideosReponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.chupu.ListVideosRequest,
 *   !proto.chupu.ListVideosReponse>}
 */
const methodInfo_Videos_ListVideos = new grpc.web.AbstractClientBase.MethodInfo(
  proto.chupu.ListVideosReponse,
  /**
   * @param {!proto.chupu.ListVideosRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.ListVideosReponse.deserializeBinary
);


/**
 * @param {!proto.chupu.ListVideosRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.chupu.ListVideosReponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chupu.ListVideosReponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chupu.VideosClient.prototype.listVideos =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chupu.Videos/ListVideos',
      request,
      metadata || {},
      methodDescriptor_Videos_ListVideos,
      callback);
};


/**
 * @param {!proto.chupu.ListVideosRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chupu.ListVideosReponse>}
 *     A native promise that resolves to the response
 */
proto.chupu.VideosPromiseClient.prototype.listVideos =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chupu.Videos/ListVideos',
      request,
      metadata || {},
      methodDescriptor_Videos_ListVideos);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chupu.GetVideoDetailRequest,
 *   !proto.chupu.Video>}
 */
const methodDescriptor_Videos_GetVideoDetails = new grpc.web.MethodDescriptor(
  '/chupu.Videos/GetVideoDetails',
  grpc.web.MethodType.UNARY,
  proto.chupu.GetVideoDetailRequest,
  proto.chupu.Video,
  /**
   * @param {!proto.chupu.GetVideoDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Video.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.chupu.GetVideoDetailRequest,
 *   !proto.chupu.Video>}
 */
const methodInfo_Videos_GetVideoDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.chupu.Video,
  /**
   * @param {!proto.chupu.GetVideoDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Video.deserializeBinary
);


/**
 * @param {!proto.chupu.GetVideoDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.chupu.Video)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chupu.Video>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chupu.VideosClient.prototype.getVideoDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chupu.Videos/GetVideoDetails',
      request,
      metadata || {},
      methodDescriptor_Videos_GetVideoDetails,
      callback);
};


/**
 * @param {!proto.chupu.GetVideoDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chupu.Video>}
 *     A native promise that resolves to the response
 */
proto.chupu.VideosPromiseClient.prototype.getVideoDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chupu.Videos/GetVideoDetails',
      request,
      metadata || {},
      methodDescriptor_Videos_GetVideoDetails);
};


module.exports = proto.chupu;

