import React from 'react';
import Tabs from './Tabs';
function Settings() {
  return (
    <React.Fragment>
      Settings Page
      <Tabs />
    </React.Fragment>
  );
}
export default Settings