/**
 * @fileoverview gRPC-Web generated client stub for chupu
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.chupu = require('./labels_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.chupu.LabelsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.chupu.LabelsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chupu.CreateLabelRequest,
 *   !proto.chupu.Label>}
 */
const methodDescriptor_Labels_CreateLabel = new grpc.web.MethodDescriptor(
  '/chupu.Labels/CreateLabel',
  grpc.web.MethodType.UNARY,
  proto.chupu.CreateLabelRequest,
  proto.chupu.Label,
  /**
   * @param {!proto.chupu.CreateLabelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Label.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.chupu.CreateLabelRequest,
 *   !proto.chupu.Label>}
 */
const methodInfo_Labels_CreateLabel = new grpc.web.AbstractClientBase.MethodInfo(
  proto.chupu.Label,
  /**
   * @param {!proto.chupu.CreateLabelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Label.deserializeBinary
);


/**
 * @param {!proto.chupu.CreateLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.chupu.Label)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chupu.Label>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chupu.LabelsClient.prototype.createLabel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chupu.Labels/CreateLabel',
      request,
      metadata || {},
      methodDescriptor_Labels_CreateLabel,
      callback);
};


/**
 * @param {!proto.chupu.CreateLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chupu.Label>}
 *     A native promise that resolves to the response
 */
proto.chupu.LabelsPromiseClient.prototype.createLabel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chupu.Labels/CreateLabel',
      request,
      metadata || {},
      methodDescriptor_Labels_CreateLabel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chupu.ListLabelsRequest,
 *   !proto.chupu.ListLabelsReponse>}
 */
const methodDescriptor_Labels_ListLabels = new grpc.web.MethodDescriptor(
  '/chupu.Labels/ListLabels',
  grpc.web.MethodType.UNARY,
  proto.chupu.ListLabelsRequest,
  proto.chupu.ListLabelsReponse,
  /**
   * @param {!proto.chupu.ListLabelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.ListLabelsReponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.chupu.ListLabelsRequest,
 *   !proto.chupu.ListLabelsReponse>}
 */
const methodInfo_Labels_ListLabels = new grpc.web.AbstractClientBase.MethodInfo(
  proto.chupu.ListLabelsReponse,
  /**
   * @param {!proto.chupu.ListLabelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.ListLabelsReponse.deserializeBinary
);


/**
 * @param {!proto.chupu.ListLabelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.chupu.ListLabelsReponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chupu.ListLabelsReponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chupu.LabelsClient.prototype.listLabels =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chupu.Labels/ListLabels',
      request,
      metadata || {},
      methodDescriptor_Labels_ListLabels,
      callback);
};


/**
 * @param {!proto.chupu.ListLabelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chupu.ListLabelsReponse>}
 *     A native promise that resolves to the response
 */
proto.chupu.LabelsPromiseClient.prototype.listLabels =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chupu.Labels/ListLabels',
      request,
      metadata || {},
      methodDescriptor_Labels_ListLabels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chupu.GetLabelRequest,
 *   !proto.chupu.Label>}
 */
const methodDescriptor_Labels_GetLabel = new grpc.web.MethodDescriptor(
  '/chupu.Labels/GetLabel',
  grpc.web.MethodType.UNARY,
  proto.chupu.GetLabelRequest,
  proto.chupu.Label,
  /**
   * @param {!proto.chupu.GetLabelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Label.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.chupu.GetLabelRequest,
 *   !proto.chupu.Label>}
 */
const methodInfo_Labels_GetLabel = new grpc.web.AbstractClientBase.MethodInfo(
  proto.chupu.Label,
  /**
   * @param {!proto.chupu.GetLabelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Label.deserializeBinary
);


/**
 * @param {!proto.chupu.GetLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.chupu.Label)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chupu.Label>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chupu.LabelsClient.prototype.getLabel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chupu.Labels/GetLabel',
      request,
      metadata || {},
      methodDescriptor_Labels_GetLabel,
      callback);
};


/**
 * @param {!proto.chupu.GetLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chupu.Label>}
 *     A native promise that resolves to the response
 */
proto.chupu.LabelsPromiseClient.prototype.getLabel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chupu.Labels/GetLabel',
      request,
      metadata || {},
      methodDescriptor_Labels_GetLabel);
};


module.exports = proto.chupu;

