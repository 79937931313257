import React, { useEffect, useState, useRef } from 'react';
import { TextField, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import Table from './Table';
import { MainCont, Header, CreateCont } from './CommonCrud';
import { TeamsClient } from './proto/teams_grpc_web_pb'
import { CreateTeamRequest, ListTeamsRequest } from './proto/teams_pb'
import useStore from "./loaderStore";

function Teams() {
  const ariaLabel = { 'aria-label': 'description' };
  const [teams, setTeams] = useState([]);
  const [rows, setTableData] = useState([]);
  const [newTeamName, setNewTeamName] = useState("");
  const setLoading = useStore(state => state.setLoading);

  const teamRef = useRef(null);

  const getTeams = () => {
    setLoading(true)
    const req = new ListTeamsRequest();
    const client = new TeamsClient(process.env.REACT_APP_ENVOY_PROXY);
    client.listTeams(req, {}, (err, resp) => {
      if (err) {
        console.log(err.code);
        console.log(err.message);
      } else {
        setTeams(resp.getTeamList())
        setLoading(false)
      }
    })
  }

  const createTeam = (name, teamRef) => {
    setLoading(true)
    const req = new CreateTeamRequest();
    const client = new TeamsClient(process.env.REACT_APP_ENVOY_PROXY);
    req.setName(name)
    client.createTeam(req, {}, (err, resp) => {
      if (err) {
        console.log(err.code);
        console.log(err.message);
      } else {
        getTeams()
        setNewTeamName("")
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    getTeams()
  }, [])

  useEffect(() => {
    const result = []
    teams.map(team => {
      result.push({ name: team.getName(), created: team.getCreateTime().toDate().toString(), updated: team.getUpdateTime().toDate().toString() })
    })
    setTableData(result)
  }, [teams])

  return (
    <MainCont>
      <Header >
        Teams
        <CreateCont>
          <TextField
            variant="standard"
            size="small"
            ref={teamRef}
            placeholder="Create a new team"
            value={newTeamName}
            inputProps={ariaLabel}
            onChange={e => setNewTeamName(e.target.value)}
            onKeyPress={(ev) => {
              console.log(`Pressed keyCode ${ev.key}`);
              if (ev.key === 'Enter') {
                // Do code here
                createTeam(newTeamName, teamRef)
                ev.preventDefault();
              }
            }} />
          <IconButton
            color="secondary"
            aria-label="create Team"
            onClick={() => createTeam(newTeamName, teamRef)}>
            <SaveIcon />
          </IconButton>
        </CreateCont>
      </Header>
      <Table rows={rows} />
    </MainCont>
  );
}
export default Teams