import React, { useState, useEffect } from 'react';
import { ImagesClient } from './proto/images_grpc_web_pb';
import { GetImageDetailsRequest } from './proto/images_pb';
import {
  useParams
} from "react-router-dom";
import { makeStyles } from '@mui/styles';
import {Snackbar, Slide} from '@mui/material';
import ImageDetailKonva from "./ImageDetailKonva";
import ImageMetadata from "./ImageMetadata";
import ImageTools from "./ImageTools";
import NewTools from "./NewTools";
import CroppedAlert from "./CroppedAlert";
import ImageDetailsFooter from "./ImageDetailsFooter";
import { ImgDetailContext } from './ImageDetailsContext';
import loaderStore from "./loaderStore";
import useStore from "./store";

const useStyles = makeStyles(() => ({
  // container: {
  //   display: 'flex',
  //   flexDirection: 'column'
  // },
  main: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  pager: {
    padding: '10px'
  }
}));

const TransitionRight = (props) => {
  return <Slide {...props} direction="left" timeout={1000}/>;
}

function ImageDetail({imageFile}) {
  let { id } = useParams();
  const classes = useStyles();
  const [imageObj, setImage] = useState(null);
  const [zoomState, setZoom] = useState(0);
  const [selectedTool, setTool] = useState('box');
  const [layerLoading, setLoading] = useState(false);
  const [selectedLayer, setLayer] = useState(null);
  const [layerLoadStart, setLayerLoadStart] = useState(null);
  const [layerLoadEnd, setLayerLoadEnd] = useState(null);
  const [displaySnack, showSnack] = useState(React.undefined);
  const setImageLoading = loaderStore(state => state.setLoading);
  const cropped = useStore(state => state.cropped);

  const handleClose = () => {
    showSnack(false);
  };

  const getImage = () => {
    var request = new GetImageDetailsRequest();
    request.setKey(id)
    const client = new ImagesClient(process.env.REACT_APP_ENVOY_PROXY);
    client.getImageDetails(request, {}, function (err, response) {
      if (err) {
        console.log(err.code);
        console.log(err.message);
        setImageLoading(false)
      } else {
        setImage(response);
        setImageLoading(false)
      }
    });
  }


  
  useEffect(() => {
    setTimeout(()=> {showSnack(false)}, 3000)
  }, [displaySnack]);

  const zoomIn = () => {
    setZoom(zoomState + 1)
  }

  const zoomOut = () => {
    setZoom(zoomState - 1)
  }

  const context = {
    selectedLayer,
    selectedTool,
    layerLoading,
    setLayer,
    setTool,
    setLoading,
    layerLoadStart,
    setLayerLoadStart,
    layerLoadEnd,
    setLayerLoadEnd,
    displaySnack,
    showSnack
  }
  
  useEffect(() => {
    if(imageFile){
      console.log("I am loading again from Image detail")
      setImage(imageFile);
    }else {
      setImageLoading(true)
      getImage();
    }
  }, []);

  return (
    <>
      {imageObj &&
        <ImgDetailContext.Provider value={context}>
          <div className={classes.main}>
              <ImageTools image={imageObj} zoomIn={zoomIn} zoomOut={zoomOut} />
              {/* <NewTools image={imageObj} zoomIn={zoomIn} zoomOut={zoomOut} /> */}
              <div>
                <ImageDetailKonva image={imageObj.getUrl()} zoomState={zoomState} />
                <ImageDetailsFooter image={imageObj}/>
              </div>
              <ImageMetadata image={imageObj} />
              <Snackbar
                open={displaySnack}
                onClose={handleClose}
                TransitionComponent={TransitionRight}
                message={`Inferred in ${(layerLoadEnd - layerLoadStart) / 1000}s`}
                key={'right'}
              />
              {/* <CroppedAlert image={imageObj}/> */}
          </div> 
        </ImgDetailContext.Provider>
      }
    </>
  );
}

export default ImageDetail