import React, { useEffect, useRef, useState } from "react";
import Konva from 'konva';
import { Layer, Line, Rect } from "react-konva";
import useStore from './store';
import { getRelativePointerPosition } from './utils/mouse'

function Polygon(props) {
  const [curMousePos, setCurMousePos] = useState([0, 0])
  const setRegionId = useStore(s => s.setRegionId);
  const setRegions = useStore(s => s.setRegions);
  const selectRegion = useStore(s => s.selectRegion);
  const setIsmouseOver = useStore(s => s.setIsmouseOver);

  const setPolygonPoints = useStore(s => s.setPolygonPoints);
  const setOnClick = useStore(s => s.setOnClick);
  const setOnMouseMove = useStore(s => s.setOnMouseMove);
  const setOnMouseDown = useStore(s => s.setOnMouseDown);
  const setOnMouseUp = useStore(s => s.setOnMouseUp);

  const { polygonPoints, stageRef } = props

  const isDrawing = useStore(state => state.isDrawing);
  const toggleDrawing = useStore(state => state.toggleIsDrawing);

  const onClick = e => {
    const clickedNotOnRegion = e.target.name() !== 'region';
    if (clickedNotOnRegion) {
      selectRegion(null);
    }
  }
  
  const onMouseDown = ({e, regionId, regions, polygonPoints, isMouseOverStartPoint}) => {
    if (isMouseOverStartPoint && polygonPoints.length >= 3) {
      const region = {
        id: regionId,
        color: Konva.Util.getRandomColor(),
        points: polygonPoints.map((p) => { let pt = {}; pt.x = p[0] ; pt.y = p[1]; return pt})
      };
      setRegions(regions.concat([region]));
      setRegionId(regionId + 1)
      setPolygonPoints([])
      toggleDrawing(false);
    } else {
      toggleDrawing(true);
      const point = getRelativePointerPosition(e.target.getStage());
      setPolygonPoints([...polygonPoints, [point.x, point.y]])
    }
  }

  const onMouseMove = ({e}) => {
    const point = getRelativePointerPosition(e.target.getStage());
    setCurMousePos([point.x, point.y])
  };

  const onMouseUp = () => {
  }
 
  const handleMouseOverStartPoint = event => {
    if (!isDrawing || polygonPoints.length < 3) return;
    event.target.scale({ x: 2, y: 2 });
    setIsmouseOver(true)
  };

  const handleMouseOutStartPoint = event => {
    event.target.scale({ x: 1, y: 1 });
    setIsmouseOver(false)
  };

  const handleDragStartPoint = event => {
  };

  const handleDragMovePoint = event => {
    const index = event.target.index - 1;
    const pos = [event.target.attrs.x, event.target.attrs.y];
    setPolygonPoints([...polygonPoints.slice(0, index), pos, ...polygonPoints.slice(index + 1)])
  };

  const handleDragEndPoint = event => {
  };

  const flattenedPoints = (polygonPoints) => {
    return polygonPoints.concat(!isDrawing ? [] : curMousePos).reduce((a, b) => a.concat(b), []);
  }

  useEffect(() => {
    stageRef.current.add(layerRef.current)
    setOnClick(onClick)
    setOnMouseMove(onMouseMove)
    setOnMouseDown(onMouseDown)
    setOnMouseUp(onMouseUp)
  }, []);  

  const layerRef = useRef();
  const fp = flattenedPoints(polygonPoints)

  return (
    <Layer ref={layerRef}>
      <Line
        points={fp}
        stroke="black"
        strokeWidth={1}
        closed={!isDrawing}
      />
      {polygonPoints.map((point, index) => {
        const width = 2;
        const x = point[0] - width / 2;
        const y = point[1] - width / 2;
        const startPointAttr =
          index === 0
            ? {
              hitStrokeWidth: 6,
              onMouseOver: handleMouseOverStartPoint,
              onMouseOut: handleMouseOutStartPoint
            }
            : null;
        return (
          <Rect
            cornerRadius={2}
            key={index}
            x={x}
            y={y}
            width={width}
            height={width}
            fill="white"
            stroke="black"
            strokeWidth={1}
            onDragStart={handleDragStartPoint}
            onDragMove={handleDragMovePoint}
            onDragEnd={handleDragEndPoint}
            draggable
            {...startPointAttr}
          />
        );
      })}
    </Layer>
  );
}

export default Polygon