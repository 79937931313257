import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
import { makeStyles } from '@mui/styles';
import {VideosClient} from './proto/videos_grpc_web_pb';
import {GetVideoDetailRequest} from './proto/videos_pb';

const useStyles = makeStyles(() => ({
  cont: {
    display: 'flex',
    flexDirection: 'column', 
    alignItems: 'center',
    maxWidth: '95vw',
  }
}));

function Home() {
  const [vidUrl, setVidUrl] = useState("");

  const getHomeVideo = () => {
    var request = new GetVideoDetailRequest();
    request.setKey('demo.mp4')
    const client = new VideosClient(process.env.REACT_APP_ENVOY_PROXY);
    client.getVideoDetails(request, {}, function (err, response) {
      if (err) {
        console.log(err.code);
        console.log(err.message);
      } else {
        setVidUrl(response.getUrl());
      }
    });
  }
  useEffect(() => {
    getHomeVideo();
  },[]);
  
  const classes = useStyles();
  return (
    <div className={classes.cont}>
      <label>Hello 👋🏽, Welcome to Chupu! Tag and label your videos & images</label>
      <ReactPlayer  url={vidUrl} controls/>
    </div>
  );
}
export default Home
