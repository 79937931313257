/**
 * @fileoverview gRPC-Web generated client stub for chupu
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.chupu = require('./teams_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.chupu.TeamsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.chupu.TeamsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chupu.CreateTeamRequest,
 *   !proto.chupu.Team>}
 */
const methodDescriptor_Teams_CreateTeam = new grpc.web.MethodDescriptor(
  '/chupu.Teams/CreateTeam',
  grpc.web.MethodType.UNARY,
  proto.chupu.CreateTeamRequest,
  proto.chupu.Team,
  /**
   * @param {!proto.chupu.CreateTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Team.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.chupu.CreateTeamRequest,
 *   !proto.chupu.Team>}
 */
const methodInfo_Teams_CreateTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.chupu.Team,
  /**
   * @param {!proto.chupu.CreateTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Team.deserializeBinary
);


/**
 * @param {!proto.chupu.CreateTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.chupu.Team)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chupu.Team>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chupu.TeamsClient.prototype.createTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chupu.Teams/CreateTeam',
      request,
      metadata || {},
      methodDescriptor_Teams_CreateTeam,
      callback);
};


/**
 * @param {!proto.chupu.CreateTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chupu.Team>}
 *     A native promise that resolves to the response
 */
proto.chupu.TeamsPromiseClient.prototype.createTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chupu.Teams/CreateTeam',
      request,
      metadata || {},
      methodDescriptor_Teams_CreateTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chupu.GetTeamRequest,
 *   !proto.chupu.Team>}
 */
const methodDescriptor_Teams_GetTeam = new grpc.web.MethodDescriptor(
  '/chupu.Teams/GetTeam',
  grpc.web.MethodType.UNARY,
  proto.chupu.GetTeamRequest,
  proto.chupu.Team,
  /**
   * @param {!proto.chupu.GetTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Team.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.chupu.GetTeamRequest,
 *   !proto.chupu.Team>}
 */
const methodInfo_Teams_GetTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.chupu.Team,
  /**
   * @param {!proto.chupu.GetTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.Team.deserializeBinary
);


/**
 * @param {!proto.chupu.GetTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.chupu.Team)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chupu.Team>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chupu.TeamsClient.prototype.getTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chupu.Teams/GetTeam',
      request,
      metadata || {},
      methodDescriptor_Teams_GetTeam,
      callback);
};


/**
 * @param {!proto.chupu.GetTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chupu.Team>}
 *     A native promise that resolves to the response
 */
proto.chupu.TeamsPromiseClient.prototype.getTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chupu.Teams/GetTeam',
      request,
      metadata || {},
      methodDescriptor_Teams_GetTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chupu.ListTeamsRequest,
 *   !proto.chupu.ListTeamsReponse>}
 */
const methodDescriptor_Teams_ListTeams = new grpc.web.MethodDescriptor(
  '/chupu.Teams/ListTeams',
  grpc.web.MethodType.UNARY,
  proto.chupu.ListTeamsRequest,
  proto.chupu.ListTeamsReponse,
  /**
   * @param {!proto.chupu.ListTeamsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.ListTeamsReponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.chupu.ListTeamsRequest,
 *   !proto.chupu.ListTeamsReponse>}
 */
const methodInfo_Teams_ListTeams = new grpc.web.AbstractClientBase.MethodInfo(
  proto.chupu.ListTeamsReponse,
  /**
   * @param {!proto.chupu.ListTeamsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chupu.ListTeamsReponse.deserializeBinary
);


/**
 * @param {!proto.chupu.ListTeamsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.chupu.ListTeamsReponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chupu.ListTeamsReponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chupu.TeamsClient.prototype.listTeams =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chupu.Teams/ListTeams',
      request,
      metadata || {},
      methodDescriptor_Teams_ListTeams,
      callback);
};


/**
 * @param {!proto.chupu.ListTeamsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chupu.ListTeamsReponse>}
 *     A native promise that resolves to the response
 */
proto.chupu.TeamsPromiseClient.prototype.listTeams =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chupu.Teams/ListTeams',
      request,
      metadata || {},
      methodDescriptor_Teams_ListTeams);
};


module.exports = proto.chupu;

