import { createContext } from 'react';

// Image Details Context
export const ImgDetailContext = createContext({
  scaleFactor: 1 / window.devicePixelRatio, // TODO: Please get rid of this junk!!!!
  selectedTool: 'box',
  setTool: () => {},

  selectedLayer: null,
  layerLoading: false,
  displaySnack: false,
  layerLoadStart: null,
  setLayerLoadStart: () => {},
  layerLoadEnd: null,
  setLayerLoadEnd: () => {},
  setLoading: () => {},

  layerData: {
    objectDetect: null,
    poseDetect: null
  },
  setLayer: () => {},
  setLayerData: () => {}
});