import React, { useEffect } from 'react';
import Konva from 'konva';
import useStore from './store';
import { getRelativePointerPosition, limitAttributes } from './utils/mouse'

const Draw = (props) => {
  const setRegions = useStore(state => state.setRegions);
  const selectRegion = useStore(s => s.selectRegion);
  const setRegionId = useStore(state => state.setRegionId);
  const setOnClick = useStore(state => state.setOnClick);
  const setOnWheel = useStore(state => state.setOnWheel);
  const setOnMouseDown = useStore(state => state.setOnMouseDown);
  const setOnMouseMove = useStore(state => state.setOnMouseMove);
  const setOnMouseUp = useStore(state => state.setOnMouseUp);
  const toggleDrawing = useStore(state => state.toggleIsDrawing);

  const { stageRef } = props

  const onClick = e => {
    const clickedNotOnRegion = e.target.name() !== 'region';
    if (clickedNotOnRegion) {
      selectRegion(null);
    }
  }

  const onWheel = e => {
    e.evt.preventDefault();
    const stage = stageRef.current;
    const dx = -e.evt.deltaX;
    const dy = -e.evt.deltaY;
    const pos = limitAttributes(stage, {
      x: stage.x() + dx,
      y: stage.y() + dy,
      scale: stage.scaleX()
    });
    stage.position(pos);
    stage.batchDraw();
  }

  const onMouseDown = ({ e, regionId, regions }) => {
    toggleDrawing(true);
    const point = getRelativePointerPosition(e.target.getStage());
    const region = {
      id: regionId,
      color: Konva.Util.getRandomColor(),
      points: [point]
    };
    setRegionId(regionId + 1)
    setRegions(regions.concat([region]));
  }

  const onMouseMove = ({ e, isDrawing, regions }) => {
    if (!isDrawing) {
      return;
    }

    const point = getRelativePointerPosition(e.target.getStage());
    const lastRegion = { ...regions[regions.length - 1] };
    lastRegion.points = lastRegion.points.concat([point]);
    regions.splice(regions.length - 1, 1);
    setRegions(regions.concat([lastRegion]));
  }

  const onMouseUp = ({ isDrawing, regions }) => {
    if (!isDrawing) {
      return;
    }
    const lastRegion = regions[regions.length - 1];
    if (lastRegion.points.length < 3) {
      regions.splice(regions.length - 1, 1);
      setRegions(regions.concat());
    }
    toggleDrawing(false);
  }

  useEffect(() => {
    setOnClick(onClick)
    setOnWheel(onWheel)
    setOnMouseDown(onMouseDown)
    setOnMouseMove(onMouseMove)
    setOnMouseUp(onMouseUp)
  }, []);

  return (<></>)
};

export default Draw