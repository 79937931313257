import React, { useEffect, useState } from 'react';
import Konva from 'konva';
import { getRelativePointerPosition, limitAttributes } from './utils/mouse'
import useStore from './store';
import { Image } from "react-konva";

const Box = (props) => {
  // const regions = useStore(state => state.regions);
  const setRegions = useStore(state => state.setRegions);
  const selectRegion = useStore(s => s.selectRegion);
  const setRegionId = useStore(state => state.setRegionId);

  const setOnClick = useStore(state => state.setOnClick);
  const setOnWheel = useStore(state => state.setOnWheel);
  const setOnMouseDown = useStore(state => state.setOnMouseDown);
  const setOnMouseMove = useStore(state => state.setOnMouseMove);
  const setOnMouseUp = useStore(state => state.setOnMouseUp);
  const toggleDrawing = useStore(state => state.toggleIsDrawing);
  
  const setCropped = useStore(state => state.setCropped);
  // const imgRef = useStore(state => state.imgRef);
  // const scale = useStore(state => state.scale);
  // console.log("Scale", scale)
  
  const onClick = e => {
    const clickedNotOnRegion = e.target.name() !== 'region';
    if (clickedNotOnRegion) {
      selectRegion(null);
    }
  }

  const onWheel = e => {
    e.evt.preventDefault();
    const stage = props.stageRef.current;

    const dx = -e.evt.deltaX;
    const dy = -e.evt.deltaY;
    const pos = limitAttributes(stage, {
      x: stage.x() + dx,
      y: stage.y() + dy,
      scale: stage.scaleX()
    });
    stage.position(pos);
    stage.batchDraw();
  }

  const onMouseDown = ({ e, regionId, regions }) => {
    toggleDrawing(true);
    const point = getRelativePointerPosition(e.target.getStage());
    const region = {
      id: regionId,
      color: Konva.Util.getRandomColor(),
      points: [point]
    };
    setRegionId(regionId + 1)
    setRegions(regions.concat([region]));
  }

  const onMouseMove = ({ e, isDrawing, regions }) => {
    if (!isDrawing) {
      return;
    }
    let newbox = []
    const point = getRelativePointerPosition(e.target.getStage());
    const lastRegion = { ...regions[regions.length - 1] };
    const p1 = { x: point.x, y: lastRegion.points[0].y }
    const p2 = { x: lastRegion.points[0].x, y: point.y }
    lastRegion.points = newbox.concat([lastRegion.points[0], p1, point, p2]);
    regions.splice(regions.length - 1, 1);
    setRegions(regions.concat([lastRegion]));
  }

  const getBox = (lastRegion, scale) => {
    let minX = 0, minY = 0, maxX = 0, maxY = 0;
    console.log("Last Region", lastRegion)
    
    lastRegion.points.forEach((p) => {
      if (minX === 0) {
        minX = Math.max(minX, p.x);
      }else {
        minX = Math.min(minX, p.x)
      }

      if (maxX === 0) {
        maxX = Math.max(maxX, p.x);
      }else {
        maxX = Math.max(maxX, p.x)
      }

      if (minY === 0) {
        minY = Math.max(minY, p.y);
      } else {
        minY = Math.min(minY, p.y)
      }
      if (maxY === 0) {
        maxY = Math.max(maxY, p.y);
      }else {
        maxY = Math.max(maxY, p.y)
      }

    })
    console.log(`Min X: ${minX}`);
    console.log(`Max X: ${maxX}`);
    console.log(`Min Y : ${minY}`);
    console.log(`Max Y: ${maxY}`);
    console.log(`Width: ${maxX - minX}`);
    console.log(`Height: ${maxY - minY}`);
    return {x: minX, y: minY, width: (maxX - minX), height: (maxY - minY)}
    // return {x: minX, y: maxY, width: 400, height: 400}
  }
  const onMouseUp = ({ isDrawing, regions, imgRef, scale }) => {
    if (!isDrawing) {
      return;
    }
    
    const lastRegion = regions[regions.length - 1];
    if (lastRegion.points.length < 3) {
      regions.splice(regions.length - 1, 1);
      setRegions(regions.concat());
    }
    let box = getBox(lastRegion, scale);
    setCropped(box)
    // let scaled = scalify(box, scale)
    // console.log("Scaled!!!",  scaled)
    // let cropped = imgRef.current.crop(box)
    // console.log('Up!!!', cropped, getBox(lastRegion), lastRegion)
    toggleDrawing(false);
  }

  useEffect(() => {
    setOnClick(onClick)
    setOnWheel(onWheel)
    setOnMouseDown(onMouseDown)
    setOnMouseMove(onMouseMove)
    setOnMouseUp(onMouseUp)
  }, []);

  return (<></>)
};

export default Box