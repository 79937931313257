import React, { useEffect } from "react";
import { Layer, Line } from "react-konva";
import { ImagesClient } from './proto/images_grpc_web_pb';
import { CreateImageEntryRequest } from './proto/images_pb';

import useStore from "./store";

const Regions = () => {
  const regions = useStore(s => s.regions);
  const isDrawing = useStore(state => state.isDrawing);
  const layerRef = React.useRef(null);

  const selectedId = useStore(s => s.selectedRigionId);
  const selectRegion = useStore(s => s.selectRegion);

  const saveNewRegion = () => {
    var request = new CreateImageEntryRequest();
    request.setFilename('22222.jpb')
    const client = new ImagesClient(process.env.REACT_APP_ENVOY_PROXY);
    client.createImageEntry(request, {}, function (err, response) {
      if (err) {
        console.log(err.code);
        console.log(err.message);
      } else {
        console.log(response);
      }
    });
  }

  useEffect(() => {
    if (isDrawing || regions.length === 0) {
      return;
    }
    saveNewRegion();
  }, [regions, isDrawing]);

  return (
    <Layer ref={layerRef}>
      {regions.map(region => {
        const isSelected = region.id === selectedId;
        return (
          <React.Fragment key={region.id}>
            {/* first we need to erase previous drawings */}
            {/* we can do it with  destination-out blend mode */}
            <Line
              globalCompositeOperation="destination-out"
              points={region.points.flatMap(p => [p.x, p.y])}
              fill="black"
              listening={false}
              closed
            />
            {/* then we just draw new region */}
            <Line
              name="region"
              points={region.points.flatMap(p => [p.x, p.y])}
              fill={region.color}
              closed
              opacity={isSelected ? 1 : 0.8}
              onClick={() => {
                selectRegion(region.id);
              }}
            />
          </React.Fragment>
        );
      })}
    </Layer>
  );
};

export default Regions