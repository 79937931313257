import React, { useEffect, useState, createContext } from "react";

import useStore from "./store";
import { withStyles, makeStyles } from '@mui/styles';
import { Divider, CircularProgress, Paper, Fade , Tooltip, Popper, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CategoryIcon from '@mui/icons-material/Category';
import CropFreeIcon from '@mui/icons-material/CropFree';
import GestureIcon from '@mui/icons-material/Gesture';
import NatIcon from '@mui/icons-material/Nat';
import RemoveIcon from '@mui/icons-material/Remove';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import TimelineIcon from '@mui/icons-material/Timeline';
import { ImgDetailContext } from "./ImageDetailsContext";

const AnchorCtx = createContext({ anchorEl: null, setAnchor: () => { } })

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500
  },
  label: {
    padding: '10px',
    fontSize: '12px'
  },
  divider: {
    width: '50%'
  },
  typography: {
    padding: '5px'
  },
  toolActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 4px #a9a9a9',
    borderRadius: '5px',
    marginRight: '20px'
  },
  toolCont: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px 10px',
    paddingBottom: '10px'
  },
  toolBtn: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '10px',
    padding: '5px',
    margin: '5px',
    cursor: 'pointer',
    backgroundColor: props => props.open ? theme.palette.secondary.main : theme.palette.primary.main,
    boxShadow: '0px 2px 4px #a9a9a9'
  },
  popper: {
    margin: '15px 5px'
  },
  paper: {
    borderRadius: '40px',
  },
  loadPopper: {
    marginRight: '5px',
    paddingRight: '4px'
  },
  loadPaper: {
    boxShadow: 'none',
    borderRadius: '40px',
    background: 'transparent',
  }
}));


const ToolBtn = ({ icon, title, pos, activeBtn, resetActive, zoomIn, zoomOut }) => {

  const [open, setOpen] = React.useState(false);
  const [obb, openBrightnessBar] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const props = { open: activeBtn === title };
  const classes = useStyles(props);
  const { setBrightness } = useStore();

  const handleClick = (newPlacement, title, setTool, selectedTool, setLayer, selectedLayer, setAnchor) => (event) => {
    resetActive(title);
    switch (title) {
      case 'Box':
        setTool('box')
        break;
      case 'Polygon':
        setTool('polygon')
        break;
      case 'Draw':
        setTool('draw')
        break;
      case 'Brightness':
        setTool('brightness')
        setAnchor(event.currentTarget);
        openBrightnessBar(!obb);
        break;
      case 'Zoom in':
        setTool('zoomIn')
        zoomIn()
        break;
      case 'Zoom out':
        setTool('zoomOut')
        zoomOut()
        break;
      case 'Objects':
        setAnchor(event.currentTarget);
        setLayer('objectDetect')
        break;
      case 'Poses':
        setAnchor(event.currentTarget);
        setLayer('poseDetect')
        break;
      case 'Segments':
        setAnchor(event.currentTarget);
        setLayer('segmentDetect')
        break;
      default:
        break;
    }
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  return (
    <ImgDetailContext.Consumer>
      {({ selectedTool, selectedLayer, setTool, setLayer }) => (
        <AnchorCtx.Consumer>
          {({ anchorEl, setAnchor}) => (
            <>
              <Popper className={classes.popper} open={obb} anchorEl={anchorEl} placement={placement} transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper className={classes.paper}>
                      <Typography className={classes.typography}>
                        <input
                          id="slider"
                          type="range"
                          min="-1"
                          max="1"
                          step="0.05"
                          defaultValue="0"
                          onChange={e => {
                            setBrightness(parseFloat(e.target.value));
                          }}
                        />
                      </Typography>
                    </Paper>
                  </Fade>
                )}
              </Popper>
              <LightTooltip title={title} placement={pos}>
                <div className={classes.toolBtn} onClick={handleClick('right', title, setTool, selectedTool, setLayer, selectedLayer, setAnchor)}>
                  {getIcon(title)}
                </div>
              </LightTooltip>
            </>
          )}
        </AnchorCtx.Consumer>
      )}
    </ImgDetailContext.Consumer>
  );
}
const getIcon = (key) => {
  switch (key) {
    case 'Box':
      return <CropFreeIcon />;
    case 'Polygon':
      return <TimelineIcon />;
    case 'Draw':
      return <GestureIcon />;
    case 'Brightness':
      return <WbSunnyIcon />
    case 'Zoom in':
      return <AddIcon />
    case 'Zoom out':
      return <RemoveIcon />
    case 'Objects':
      return <CategoryIcon />
    case 'Poses':
      return <NatIcon />
    case 'Segments':
      return <AutoFixHighIcon />
    case '3D Bounding Box':
      return <ViewInArIcon />
    default:
      return <AddIcon />
  }
}
const Tools = (props) => {
  const classes = useStyles();
  const { activeBtn, resetActive, zoomIn, zoomOut, selectedTool } = props;
  return (
    <div className={classes.toolCont}>
      <label className={classes.label}>Tools</label>
      <ToolBtn title={"Zoom in"} pos={"right"} activeBtn={activeBtn} resetActive={resetActive} zoomIn={zoomIn} zoomOut={zoomOut}  />
      <ToolBtn title={"Zoom out"} pos={"right"} activeBtn={activeBtn} resetActive={resetActive} zoomIn={zoomIn} zoomOut={zoomOut}  />
      <ToolBtn title={"Brightness"} pos={"top"} activeBtn={activeBtn} resetActive={resetActive} zoomIn={zoomIn} zoomOut={zoomOut}  />
      <ToolBtn title={"Draw"} pos={"right"} activeBtn={activeBtn} resetActive={resetActive} zoomIn={zoomIn} zoomOut={zoomOut}  />
      <ToolBtn title={"Box"} pos={"right"} activeBtn={activeBtn} resetActive={resetActive} zoomIn={zoomIn} zoomOut={zoomOut}  />
      <ToolBtn title={"Polygon"} pos={"right"} activeBtn={activeBtn} resetActive={resetActive} zoomIn={zoomIn} zoomOut={zoomOut}  />
    </div>
  );
}

const Layers = (props) => {
  const classes = useStyles();
  const { activeBtn, resetActive, zoomIn, zoomOut, selectedLayer } = props;
  return (
    <div className={classes.toolCont}>
      <label className={classes.label}>Auto label</label>
      <ToolBtn title={"Objects"} pos={"right"} activeBtn={activeBtn} resetActive={resetActive} zoomIn={zoomIn} zoomOut={zoomOut}  />
      <ToolBtn title={"Poses"} pos={"right"} activeBtn={activeBtn} resetActive={resetActive} zoomIn={zoomIn} zoomOut={zoomOut}  />
      <ToolBtn title={"Segments"} pos={"right"} activeBtn={activeBtn} resetActive={resetActive} zoomIn={zoomIn} zoomOut={zoomOut}  />
      <ToolBtn title={"3D Bounding Box"} pos={"bottom"} activeBtn={activeBtn} resetActive={resetActive} zoomIn={zoomIn} zoomOut={zoomOut}  />
    </div>
);
}

const ImageTools = (props) => {
  const { zoomIn, zoomOut } = props;
  const classes = useStyles();
  const [activeBtn, setOpen] = React.useState();
  const [anchorEl, setAnchor] = useState(null)


  const resetActive = (title) => {
    setOpen(title)
  }

  useEffect(() => {
    setOpen("Box")
  }, []);

  return (
    <ImgDetailContext.Consumer>
      {({ selectedTool, selectedLayer, setLayer, layerLoading }) => (
        <div className={classes.toolActions}>
          <AnchorCtx.Provider value={{ anchorEl, setAnchor }}>
            <Tools activeBtn={activeBtn} resetActive={resetActive} zoomIn={zoomIn} zoomOut={zoomOut} selectedTool={selectedTool} />
            <Divider className={classes.divider} />
            <Layers activeBtn={activeBtn} resetActive={resetActive} zoomIn={zoomIn} zoomOut={zoomOut} selectedLayer={selectedLayer} setLayer={setLayer} />
          </AnchorCtx.Provider>
          <Popper className={classes.loadPopper} open={layerLoading} anchorEl={anchorEl} placement={'left'} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={classes.loadPaper}>
                  <CircularProgress color="secondary" size={10} />
                </Paper>
              </Fade>
            )}
          </Popper>
        </div>
      )}
    </ImgDetailContext.Consumer>
  );
};

export default ImageTools