import create from "zustand";

const [videoStore] = create(set => ({
  filename: '',
  playing: false,
  setPlaying: (val) => set(state => ({ playing: val })),
  tempPlayState: false,
  setTempPlayState: (val) => set(state => ({ tempPlayState: val })),
  ready: false,
  setReady: (val) => set(state => ({ ready: val })),
  volume: 0.1,
  setVolume: (val) => set(state => ({ volume: val })),
  sliderThumb: null,
  setSliderThumb: (val) => set(state => ({ sliderThumb: val })),
  dialogOpen: false,
  setDialogOpen: (val) => set(state => ({ dialogOpen: val })),
  tagClips: {},
  updateTagClips: (val) => set(state => ({ tagClips: val })),
  runTags: [],  
  setRunTags: (val) => set(state => ({ runTags: val})),
  stopTags: [],
  setStopTags: (val) => set(state => ({ stopTags: val })),
  position: 0,
  played: 0, 
  onProgress: (val) => set(state => ({ position: val.playedSeconds, played: val.played })),
}));

export default videoStore;