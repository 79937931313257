import React, { useEffect, useState } from "react";
import useImage from "use-image";
import Konva from 'konva';
import { Layer } from "react-konva";

import useStore from './store';
import loaderStore from "./loaderStore";

const posenet = require('@tensorflow-models/posenet');


const DetectedPose = (props) => {
  const scale = useStore(state => state.scaleFactor);
  const setLoading = loaderStore(state => state.setLoading);
  
  const [image] = useImage(props.image, "Anonymous");
  const layerRef = React.useRef(null);
  const [imgPose, setPose] = useState(null)

  const { stageRef, setLayerLoadStart, setLayerLoadEnd, showSnack, imageRef} = props;


  const drawPoses = (posePts) => {
    var points = []
    var newEstimates = {}
   
    posePts.keypoints.map((point) => {
      const transform = layerRef.current.getAbsoluteTransform().copy();
      // to detect relative position we need to invert transform
      transform.invert();
    
      // get pointer (say mouse or touch) position
      const pos = point.position

      // now we find relative point
      const newPos = transform.point(pos);
      newEstimates[point.part] = newPos;

      points.push(parseInt(newPos.x))
      points.push(parseInt(newPos.y))
    })

    var faceLines = new Konva.Line({
      points: [
        newEstimates['leftEar'].x * scale, newEstimates['leftEar'].y * scale, 
        newEstimates['leftEye'].x * scale,newEstimates['leftEye'].y * scale, 
        newEstimates['nose'].x * scale,newEstimates['nose'].y * scale, 
        newEstimates['rightEye'].x * scale, newEstimates['rightEye'].y * scale, 
        newEstimates['rightEar'].x * scale, newEstimates['rightEar'].y * scale
      ],
      stroke: 'red',
      strokeWidth: 2,
      lineCap: 'round',
      lineJoin: 'round'
    });

    var torsoLines = new Konva.Line({
      points: [
        newEstimates['leftWrist'].x * scale, newEstimates['leftWrist'].y * scale , 
        newEstimates['leftElbow'].x * scale,newEstimates['leftElbow'].y * scale, 
        newEstimates['leftShoulder'].x * scale,newEstimates['leftShoulder'].y * scale, 
        newEstimates['rightShoulder'].x * scale, newEstimates['rightShoulder'].y * scale, 
        newEstimates['rightElbow'].x * scale, newEstimates['rightElbow'].y * scale,
        newEstimates['rightWrist'].x * scale, newEstimates['rightWrist'].y * scale
      ],
      stroke: 'green',
      strokeWidth: 2,
      lineCap: 'round',
      lineJoin: 'round'
    });

    var waistLines = new Konva.Line({
      points: [
        newEstimates['leftAnkle'].x * scale, newEstimates['leftAnkle'].y * scale , 
        newEstimates['leftKnee'].x * scale,newEstimates['leftKnee'].y * scale, 
        newEstimates['leftHip'].x * scale,newEstimates['leftHip'].y * scale, 
        newEstimates['rightHip'].x * scale, newEstimates['rightHip'].y * scale, 
        newEstimates['rightKnee'].x * scale, newEstimates['rightKnee'].y * scale,
        newEstimates['rightAnkle'].x * scale, newEstimates['rightAnkle'].y * scale
      ],
      stroke: 'blue',
      strokeWidth: 2,
      lineCap: 'round',
      lineJoin: 'round'
    });

    layerRef.current.add(faceLines)
    layerRef.current.add(torsoLines)
    layerRef.current.add(waistLines)

    stageRef.current.add(layerRef.current)
    setLoading(false)
    setLayerLoadEnd(Date.now())
    showSnack(true)   
  }

  useEffect(() => {
    if(!imgPose || !stageRef){
      return
    }
    imgPose.map(drawPoses)

  }, [imgPose]);

  useEffect(() => {
    if(!image){
      return
    }

    setLoading(true)
    setLayerLoadStart(Date.now())

    const canvas = imageRef.current.getCanvas()._canvas;

    // async function estimatePoseOnImage(imageElement) {
    //   // load the posenet model from a checkpoint
    //   const net = await posenet.load({
    //     // architecture: 'MobileNetV1',
    //     // outputStride: 16,
    //     // inputResolution: { width: 480, height: 640 },
    //     // multiplier: 1
    //     architecture: 'ResNet50',
    //     outputStride: 32,
    //     inputResolution: { width: 257, height: 200 },
    //     quantBytes: 2
    //   });
    
    //   const pose = await net.estimateSinglePose(imageElement, {
    //     flipHorizontal: false
    //   });
    
    //   return pose;
    // }
    
    // estimatePoseOnImage(canvas)
    // .then((pose) => {
    //   setPose(pose)  
    // })

    const estimateMultiPoseOnImage = async (imageElement) => {
      // load the posenet model from a checkpoint
      const net = await posenet.load({
        architecture: 'ResNet50',
        outputStride: 32,
        inputResolution: { width: 257, height: 200 },
        quantBytes: 2
      });
    
      const poses = await net.estimateMultiplePoses(imageElement, {
        flipHorizontal: false
      });
    
      return poses;
    }
    
    estimateMultiPoseOnImage(canvas)
    .then((poses) => {
      setPose(poses)  
    })

  }, [image]);

  return (
    <Layer ref={layerRef} />
  );
};

export default DetectedPose