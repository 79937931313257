import React, { useEffect, useRef } from "react";
import { Image, Layer } from "react-konva";
import useImage from "use-image";
import useStore from "./store";

const BaseImage = (props) => {

  const { setBaseImageRef } = props;
  const [image] = useImage(props.image, "Anonymous");
  const layerRef = useRef(null);
  const imageRef = useRef(null);
  const setImageSize = useStore(state => state.setImageSize);
  const setScale = useStore(state => state.setScale);
  const setSize = useStore(state => state.setSize);
  const width = useStore(state => state.width);
  const height = useStore(state => state.height);
  const setImgRef = useStore(state => state.setImgRef);

  const { brightness } = useStore();

  useEffect(() => {
    if (!image) {
      return
    }
    const scale = Math.min(width / image.width, height / image.height);
    setScale(scale);
    setImageSize({ width: image.width, height: image.height });

    const ratio = image.width / image.height;
    setSize({
      width: width,
      height: width / ratio
    });
  }, [image, width, height, setScale, setImageSize, setSize]);



  useEffect(() => {
    const canvas = layerRef.current.getCanvas()._canvas;
    canvas.style.filter = `brightness(${(brightness + 1) * 100}%)`;
  }, [brightness]);

  useEffect(() => {
    console.log('WTF I am loading again from Baseimage', imageRef)
    setImgRef(imageRef)
    setBaseImageRef(layerRef)
  }, [])

  return (
    <Layer ref={layerRef}>
      <Image image={image} ref={imageRef}/>
    </Layer>
  );
};
export default BaseImage