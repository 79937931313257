import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { Image, Layer, Stage } from "react-konva";
import useStore from "./store";
import { styled } from '@mui/material/styles';

const DialCont = styled(Dialog)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}));
const ImageCont = styled('div')(({ theme }) => ({
  alignSelf:'center',
  paddingBottom: '20px'
}));

const emails = ['username@gmail.com', 'user02@gmail.com'];

function SimpleDialog(props) {
  const { onClose, selectedValue, open, image, cropped } = props;
  
  const scale = useStore(state => state.scale);
  const imgRef = useStore(state => state.imgRef);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  useEffect(() => {
    if (cropped) {
      console.log("Cropped", cropped, image)
    }
  }, [cropped])

  return (
    <DialCont onClose={handleClose} open={open} >
      <DialogTitle>Set backup account</DialogTitle>
      {cropped &&
        <ImageCont>
        <Stage
                  height={scale * cropped.height}
                  width={ scale * cropped.width}
                  // height={scale*cropped.getCropHeight()}
                  // width={scale*cropped.getCropWidth()}
                  scaleX={1/scale}
                  scaleY={1/scale}
                >
                  <Layer>
                    <Image image={imgRef.current.getImage()} crop={cropped} />
                  </Layer>
                </Stage>
        </ImageCont>
        
      }
    </DialCont>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function CroppedAlert({ image }) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const cropped = useStore(state => state.cropped);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <Typography variant="subtitle1" component="div">
        Selected: {selectedValue}
      </Typography>
      <br />
      <Button variant="outlined" onClick={handleClickOpen}>
        Open simple dialog
      </Button>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        image={image}
        cropped={cropped}
      />
    </div>
  );
}
