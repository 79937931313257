import React, { useRef, useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import useStore from "./store";
import MetaInfoCont from './MetaInfoCont';
import { Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LabelsClient } from './proto/labels_grpc_web_pb'
import { ListLabelsRequest } from './proto/labels_pb'
// import loaderStore from "./loaderStore";

const TagName = styled('input')(({ theme }) => ({
  fontFamily: 'Roboto',
  border: 'none',
  background: 'transparent',
  color: theme.palette.text.primary
}));

const SortableItem = SortableElement(({ region, sortIndex, onRemove }) => {
  const inputElement = useRef(null);
  const isDrawing = useStore(state => state.isDrawing);
  // const setLoading = loaderStore(state => state.setLoading);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if (isDrawing || !inputElement.current) {
      return;
    }
    inputElement.current.focus();
  }, [isDrawing]);

  const tagNamed = (tagName) => {
    console.log("Tag has been named", tagName)
  }

  // const options = ['Option 1', 'Option 2'];

  const getLabels = () => {
    // setLoading(true)
    const req = new ListLabelsRequest();
    const client = new LabelsClient(process.env.REACT_APP_ENVOY_PROXY);
    client.listLabels(req, {}, (err, resp) => {
      if (err) {
        console.log(err.code);
        console.log(err.message);
      } else {
        const tags = resp.getLabelList()
        const tempList = tags.map(tag => {
          return tag.getName()
        });
        setLabels(tempList)
        // setLoading(false)
      }
    })
  }

  useEffect(() => {
    // setLoading(true)
    getLabels()
  },[])

  return (
    <div
      className="region"
      style={{
        lineHeight: 1,
        boxShadow: `0 0 5px ${region.color}`,
        border: `1px solid ${region.color}`,
        margin: '10px'
      }}
    >
      <Autocomplete
        id="custom-input-demo"
        options={labels}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <TagName
              autoFocus        
              type="text"
              placeholder={"Tag " + region.id}
              onFocus={e => e.currentTarget.select()}
              onBlur={e => tagNamed(e.currentTarget.value)}
              ref={inputElement}
              {...params.inputProps}
            />
            <button
              style={{
                borderWidth: '1px',
                borderColor: 'black',
                borderRadius: '4px',
                backgroundColor: 'white'
              }}
              onClick={() => {
                onRemove(sortIndex);
              }}
            >
              X
            </button>
          </div>
        )}
      />
      
    </div>
  );
});

const RegionsCont = styled('div')(({ theme }) => ({
  borderTop: '1px solid #d3d3d3',
  paddingTop: '10px',
  marginTop: '10px',
  maxHeight: '40vh',
  overflow: 'scroll'
}));

const SortableList = SortableContainer(({ items, onRemove }) => {
  return (
    <MetaInfoCont>
      <b>Region Tags</b>
      <RegionsCont>
        {items.length === 0 && <div>No tags to display - draw shapes around objects to start tagging.</div>}
        {items.map((region, index) => (
          <SortableItem
            key={`item-${region.id}`}
            index={index}
            region={region}
            onRemove={onRemove}
            sortIndex={index}
          />
        ))}
      </RegionsCont>
    </MetaInfoCont>
  );
});

const RegionsList = () => {
  const regions = useStore(s => s.regions);
  const setRegions = useStore(s => s.setRegions);

  return (
    <SortableList
      items={regions}
      onSortEnd={({ oldIndex, newIndex }) => {
        setRegions(arrayMove(regions, oldIndex, newIndex));
      }}
      onRemove={index => {
        regions.splice(index, 1);
        setRegions(regions.concat());
      }}
    />
  );
};
export default RegionsList