import { styled } from '@mui/material/styles';

export const MainCont = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column'
}));

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  padding: '10px',
  gap: '10px',
}));

export const CreateCont = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}));

export const Input = styled('input')({
  display: 'none',
});